import React, { Component } from "react";
import bwlogo from '../assets/bwlogo.svg';
import insta from "../assets/instagram.svg";
import facebook from "../assets/facebook.svg";
import telephone from "../assets/telephone.svg";
import email from "../assets/email.svg";
import "../App.css";
import { Container, Row, Col } from "reactstrap";
import AboutUsHeader from "../assets/AboutUsHeader.png";
import AboutUsSimplicity from '../assets/AboutUsSimplicity.png';
import AboutUsBTO from '../assets/AboutUsBTO.png';
import AboutUsFreeShipping from '../assets/AboutUsFreeShipping.png';
import AboutUsCustomerService from '../assets/AboutUSCustomerService.png';
import NoHassle from "../assets/No Hassle.png";
import AdvancedTechnology from "../assets/Advanced Technology.png";
import WeDeliverValue from "../assets/We Deliver Value.png";
import BestPrices from '../assets/Best Prices.png';
import EliminateMarkup from '../assets/Eliminate Markup.png';
import CertifiedSuppliers from '../assets/Certified Suppliers.png';
import ShippingIncluded from '../assets/Shipping Included.png';
import OrderAutomation from '../assets/Order Automation.png';
import BuildToOrder from '../assets/BuildToOrder.png';
import ProductBundles from '../assets/Product Bundles.png';
import GlobalLogistics from '../assets/Global Logistics.png';
import WhyBDP from '../assets/WhyBDPSlider.jpg';





class WhyBuyDirect extends Component {

   
  render() {
    return (
<div>
    <div id="top"></div>
    <Container>
      
      <Row>
        <Col colSpan="3" align="middle">
        <div class="cont">
  <img className="cont-img" src={WhyBDP} alt="Snow" />
  {/* <button class="btn">Button</button> */}
</div>
          
        </Col>
      </Row>
      
      <Row>
    <Col><br/>
    <div className="subTextWhyBuyCenter">
    <img src={BestPrices} align="middle"/><br/><br/><br/>
            <b>Best Prices</b><br/><br/>
    </div>
    
      <span className="subTextWhyBuy">Our direct purchasing model delivers better value and profitability for your projects.  Pricing and terms are already negotiated to deliver the best pricing in the market. </span>
    </Col>
    <Col><br/>
    <div className="subTextWhyBuyCenter">
    <img src={EliminateMarkup}/><br/><br/><br/>
    <b>Eliminate Markup</b><br/><br/>
    </div>
    <span className="subTextWhyBuy">We provide a superior solution for professionals who need large quantities of high-quality products.  Buying direct eliminates costly distributor markup to deliver greater savings and increased profitability.   </span>
    </Col>
    
    <Col><br/>
    <div className="subTextWhyBuyCenter">
    <img src={CertifiedSuppliers} /><br/><br/><br/>
    <b>Certified Suppliers </b><br/><br/>
      </div>
    
    <span className="subTextWhyBuy">Our manufacturing partners best-in-class products with proven track records of building high-quality, best-in-class products backed by industry quality certifications and a proven record of performance. </span>
    </Col>
  </Row>
  <Row>
        
        <Col colspan="3" className="spacer-text"> 
        </Col>
      </Row>
          </Container>

          <Container>
      <Row>
        <Col colSpan="3" align="left">
          <div id="procurement"></div>
          <h2>Simplify Your Procurement Process</h2>
        </Col>
      </Row>
    
      <Row> 
    <Col border="1"><br/>
    <div className="subTextWhyBuyCenter">
    <img src={NoHassle} align="middle"/><br/><br/><br/>
            <b>No Hassle</b><br/><br/> 
    </div>
    
      <span className="subText2">

Say goodbye telephone tag and endless emails of the traditional quoting process.  Easily review pricing and product information online – saving time and money.  </span>
    </Col>
    <Col><br/>
    <div className="subTextWhyBuyCenter">
    <img src={ShippingIncluded} align="middle"/><br/><br/><br/>
            <b>Shipping Included</b><br/><br/> 
    </div>
    
    <span className="subText2">From manufacturers around the world, online pricing includes shipping, handling, and tariffs to your destination of choice in the United States (lower 48) and Canada.   </span>
    </Col>
    
    <Col><br/>
    <div className="subTextWhyBuyCenter">
    <img src={OrderAutomation} align="middle"/><br/><br/><br/>
            <b>Order Automation</b><br/><br/> 
    </div>
    <span className="subText2">Easily submit orders and payment online.  Notifications provide real-time visibility to when your order goes into production, ships from the factory, and when you can expect delivery to your facility or jobsite.  </span>
    </Col>
  </Row>
  <Row>
        
        <Col colspan="2" className="spacer-text"> 
        </Col>
      </Row>
          </Container>

          <Container>
      <Row>
        <Col colSpan="3" align="left">
          <div id="flexible"></div>
          <h2>Flexible Product Options</h2>
        </Col>
      </Row>
     
      <Row>
    <Col border="1"><br/>
    <div className="subTextWhyBuyCenter">
    <img src={BuildToOrder} align="middle"/><br/><br/><br/>
            <b>Build To Order</b><br/><br/> 
    </div>
      <span className="subText2">BuyDirectPro certified manufacturer partners build goods at the time of order ensuring products are produced with the latest designs and components.  </span>
    </Col>
    <Col><br/>
    <div className="subTextWhyBuyCenter">
    <img src={ProductBundles} align="middle"/><br/><br/><br/>
            <b>Product Bundles</b><br/><br/> 
    </div>
    <span className="subText2">Product bundles allow for different products to be combined into a single shipment.  If your project requires various products, efficiently combine within a shipping container and deliver directly to your jobsite. </span>
    </Col>
    
    <Col><br/>
    <div className="subTextWhyBuyCenter">
    <img src={GlobalLogistics} align="middle"/><br/><br/><br/>
            <b>Global Logistics</b><br/><br/> 
    </div>
    
    <span className="subText2">We manage production and transportation around the world to control all logistical aspects of your order from the factory to your destination of choice.  </span>
    </Col>
  </Row>
  <Row>
        
        <Col colspan="2" className="spacer-text"> 
        </Col>
      </Row>
          </Container>
</div>
);
}
}
export default WhyBuyDirect;