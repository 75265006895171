import React, { Component } from "react";
import bwlogo from '../assets/bwlogo.svg';
import { Row, Col } from "reactstrap";
import insta from "../assets/instagram.svg";
import facebook from "../assets/facebook.svg";
import telephone from "../assets/telephone.svg";
import email from "../assets/email.svg";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { HashLink as Link } from 'react-router-hash-link';
import Scrollchor from 'react-scrollchor';  
import Collapsible from 'react-collapsible';
// import AccordianWrapper from 'custom-react-accordion';
import '../App.css';



class FAQ extends Component {

   
    render() {
      return (
  <div>
    <br/><br/>
  {/* <Collapsible trigger="Who is BuyDirectPro?"  className="Accordian-Header">
      <p>
      BuyDirectPro enables professionals to source top-quality, certified products directly 
      from best-in-class manufacturers. Our online marketplace features factory-direct pricing, 
      the convenience of ordering online, and automated order fulfillment
      </p>
  </Collapsible>
  <Collapsible trigger="How does the BuyDirectPro order fulfillment process work?" className="Accordian-Header">
      <p>
      All orders are placed, confirmed and funded online.  Our manufacturing supplier partners fulfill orders
      using a Build-To-Order (BTO) production approach.  At the completion of order production, products are shipped in 20 & 40 foot containers directly 
      from factory facilities to your location of choice within the US (contiguous lower 48 states) and Canada.
      </p>
  </Collapsible>
  <Collapsible trigger="Can BuyDirectPro customize products to my design requirements?" className="Accordian-Header">
      <p>
      Yes, we work closely with our supplier partners to design and build customized products to meet your specific requirements.
       </p>
  </Collapsible> */}
       <Tabs>
                        <TabList>
                            <Tab>General FAQ</Tab>
                            <Tab>Orders</Tab>
                            <Tab>Payment and Sales Tax</Tab>
                            <Tab>Shipping and Delivery</Tab>
                        </TabList>

    <TabPanel>
    <div id="top">
    <h2>General FAQ</h2>
    Buydirectpro is committed to helping you find accurate information and answers to your questions.  Please review the FAQs below. If your questions are not answered on these pages or you would prefer to speak with a customer service representative, please contact us toll-free at 800-xxx-xxxx or via email at cs@buydirectpro.com.
    <hr></hr>
     <Scrollchor to="#who-is" className="nav-link">1. Who is Buydirectpro?</Scrollchor>
     <Scrollchor to="#how-does" className="nav-link"> 2. How does the Buydirectpro buying process work?</Scrollchor>
     <Scrollchor to="#can-customize" className="nav-link"> 3. Can Buydirectpro customize products to my design requirements?</Scrollchor>
     <Scrollchor to="#stock-inventory" className="nav-link">4. Does Buydirectpro stock inventory?</Scrollchor>
     <Scrollchor to="#who-eligible" className="nav-link">5. Who is eligible to purchase from Buydirectpro?</Scrollchor>
     <Scrollchor to="#showroom-location" className="nav-link">6. Does Buydirectpro operate a showroom location?</Scrollchor>
     <Scrollchor to="#where-manufactured" className="nav-link">7. Where are your products manufactured?</Scrollchor>
    <hr></hr>
    </div>
    <div id="who-is"><span className="faq-header">1. Who is Buydirectpro?</span><br/>
<span className="faq-text">Buydirectpro is an online marketplace that leverages powerful technology to connect you directly to manufacturers of high-quality products at the best possible prices. </span>
<br/>
<Scrollchor to="#top" className="nav-link">Back to Top</Scrollchor><br/><br/></div>

<div id="how-does"><span className="faq-header">2.  How does the Buydirectpro buying process work?</span><br/>
<span className="faq-text">We use a Build-to-Order (BTO) production approach where products are built only after customer order is confirmed and payment is received.   Our marketplace system coordinates order processing from you to our manufacturing partners who build products and ship 20 & 40 foot containers directly from the factory to your physical location of choice in the US and Canada.</span>
<br/>
<Scrollchor to="#top" className="nav-link">Back to Top</Scrollchor><br/><br/></div>

<div id="can-customize"><span className="faq-header">3. Can Buydirectpro customize products to my design requirements?</span><br/>
<span className="faq-text">Yes!  If you need to customize a product based on unique design specifications, we work closely with our manufacturer partners to build and deliver products that meet your specific requirements.</span>
<br/>
<Scrollchor to="#top" className="nav-link">Back to Top</Scrollchor><br/><br/></div>
<br/>
<div id="stock-inventory"><span className="faq-header">4. Does Buydirectpro stock inventory?</span><br/>
<span className="faq-text">We do not stock inventory.  We avoid costs associated with inventory using a Build-to-Order (BTO) model in order to to deliver the best prices to our customers.</span>
<br/>
<Scrollchor to="#top" className="nav-link">Back to Top</Scrollchor><br/><br/></div>

<div id="who-eligible"><span className="faq-header">5. Who is eligible to purchase from Buydirectpro?</span><br/>
<span className="faq-text">Our products are offered to any organization seeking to purchase container quantities of high quality products at the best prices with convenience and efficiency of online ordering and delivery.</span>
<br/>
<Scrollchor to="#top" className="nav-link">Back to Top</Scrollchor><br/><br/></div>

<div id="showroom-location"><span className="faq-header">6. Does Buydirectpro operate a showroom location?</span><br/>
<span className="faq-text">Buydirectpro is an online marketplace and does not operate any storefront or showroom locations.  To be able to see and review products, please see Are product samples available?</span>
<br/>
<Scrollchor to="#top" className="nav-link">Back to Top</Scrollchor><br/><br/></div>

<div id="where-manufactured"><span className="faq-header">7. Where are your products manufactured?</span><br/>
<span className="faq-text">Our products are produced by top quality manufacturers around the world.  Each product detail page contains a specifications section with the “Country of Origin”, denoting where the product is manufactured. </span>
<br/>
<Scrollchor to="#top" className="nav-link">Back to Top</Scrollchor><br/><br/></div>
    </TabPanel>
    <TabPanel>
    <div id="topOrders">
    <h2>Orders FAQ</h2>
    <hr></hr>
    <Scrollchor to="#assistance-do" className="nav-link">1. I need assistance.  What should I do?</Scrollchor>
    <Scrollchor to="#assistance-order-do" className="nav-link">2. I need assistance regarding an open order.  What should I do?</Scrollchor>
    <Scrollchor to="#min-qty" className="nav-link">3. What is the minimum order quantity?</Scrollchor>
    <Scrollchor to="#less-min-qty" className="nav-link">4. Is it possible to order less than minimum order quantities?</Scrollchor>
    <Scrollchor to="#mix-match" className="nav-link">5. Can I mix and match products within a container order?</Scrollchor>
    <Scrollchor to="#after-order-place" className="nav-link">6. What happens after I place an order?</Scrollchor>
    <Scrollchor to="#shipping-address-order" className="nav-link">7. Can I change my shipping address after an order is placed?</Scrollchor>
    <Scrollchor to="#cancel-order" className="nav-link">8. I need to cancel my order.  What is your order cancellation policy?</Scrollchor>
    <Scrollchor to="#see-before-buy" className="nav-link">9. Is it possible to see the product before I buy?</Scrollchor>
    <Scrollchor to="#sample-products-available" className="nav-link">10. Are product samples available?</Scrollchor>
    <Scrollchor to="#accesories" className="nav-link">11. Are accessories sold with products?</Scrollchor>
    <Scrollchor to="#volume-discount" className="nav-link">12. Are volume discounts offered?</Scrollchor>
    <Scrollchor to="#monetary-unit" className="nav-link">13. What is the monetary unit for prices listed on Buydirectpro?  (USD)</Scrollchor>
    <Scrollchor to="#returns" className="nav-link">14. Can extra product be returned?</Scrollchor>
    <Scrollchor to="#warranty" className="nav-link">15. Where can I find the manufacturer warranty information?</Scrollchor>


    <hr></hr>
    </div>

    <div id="assistance-do"><span className="faq-header">1. I need assistance.  What should I do?</span><br/>
<span className="faq-text">We will be glad to help you!  If you have general questions, need sales assistance, have technical product questions please contact a Buydirectpro representative at 800-xxx-xxxx or email sales@buydirectpro.com. </span>
<br/>
<Scrollchor to="#topOrders" className="topOrders">Back to Top</Scrollchor><br/><br/></div>

<div id="assistance-order-do"><span className="faq-header">2. I need assistance regarding an open order.  What should I do?</span><br/>
<span className="faq-text">Email Buydirectpro customer service at cs@buydirectpro.com with your order number and questions.  A representative will contact you to resolve.</span>
<br/>
<Scrollchor to="#topOrders" className="topOrders">Back to Top</Scrollchor><br/><br/></div>

<div id="min-qty"><span className="faq-header">3. What is the minimum order quantity?</span><br/>
<span className="faq-text">Buydirectpro offers two options:  a 20 foot half-container and a 40 foot full container.   Refer to the product details to determine the product unit quantity count contained within each container size. </span>
<br/>
<Scrollchor to="#topOrders" className="topOrders">Back to Top</Scrollchor><br/><br/></div>

<div id="less-min-qty"><span className="faq-header">4. Is it possible to order less than minimum order quantities?</span><br/>
<span className="faq-text"> To offer best prices for bulk buying requirements, we only offer 20 foot half-container and 40 foot full container quantities.</span>
<br/>
<Scrollchor to="#topOrders" className="topOrders">Back to Top</Scrollchor><br/><br/></div>


<div id="mix-match"><span className="faq-header">5. Can different products be combined within a single container order?</span><br/>
<span className="faq-text">TBD</span><br/>

<Scrollchor to="#topOrders" className="topOrders">Back to Top</Scrollchor><br/><br/></div>

<div id="after-order-place"><span className="faq-header">6. What happens after I place an order?</span><br/>
<span className="faq-text">You will receive an email order confirmation and payment confirmation.  
We extend a grace period of 48 hours from the timestamp of your order to cancel the order without penalty.
  If you need to cancel the order following the initial 48-hour period, please refer to I need to cancel an order. 
   What is your order cancellation policy?  As the order moves into production, you will receive updates regarding production status, 
   shipping status and tracking as well as projected delivery time to plan for unloading the container.</span><br/>

<Scrollchor to="#topOrders" className="topOrders">Back to Top</Scrollchor><br/><br/></div>

<div id="shipping-address-order"><span className="faq-header">7. Can I change my shipping address after an order is placed?</span><br/>
<span className="faq-text">If you need to change the shipping address between the time you place the order but before production is completed, we can accommodate one change.
  We will be unable to change the address after production is completed and released to shipping. </span><br/>

<Scrollchor to="#topOrders" className="topOrders">Back to Top</Scrollchor><br/><br/></div>

<div id="cancel-order"><span className="faq-header">8. I need to cancel my order.  What is your order cancellation policy?</span><br/>
<span className="faq-text">In general, all orders are non-cancellable, non-returnable (NCNR). We extend a grace period of 48 hours 
from the timestamp of your order to cancel the order without penalty.  We need to define how to cancel the order if within the 48-hour period.  
If you need to cancel an order following the 48-hour grace period, Buydirectpro will submit an order cancellation request to our manufacturing partner for review and approval.  If approved, we will process an order cancellation and access you a 15% penalty  deduct from the refund proceeds.  
Otherwise, an order will be considered NCNR. </span><br/>

<Scrollchor to="#topOrders" className="topOrders">Back to Top</Scrollchor><br/><br/></div>

<div id="see-before-buy"><span className="faq-header">9. Is it possible to see the product before I buy?</span><br/>
<span className="faq-text"> If you need to see the product before you buy, you may order a product sample through the Sample Product Loaner Program (SPLP). 
 Please refer to Are product samples available?</span><br></br>

<Scrollchor to="#topOrders" className="topOrders">Back to Top</Scrollchor><br/><br/></div>

<div id="sample-products-available"><span className="faq-header">10. Are product samples available?</span><br/>
<span className="faq-text">Buydirectpro offers a Sample Product Loaner Program (SPLP) offers a valuable service to our customers 
by allowing you to check out samples for a 14 day period and return to us at no charge. 
 A limited supply of samples are available for loan.  For full details, refer to Sample Product Loaner Program.
 <br/><br/>
 Please note samples are not provided as replacements for existing equipment or for any other purpose. 
 Sample product loaners should be returned in operable condition with all parts and accessories after 14 days. 
  Normal wear and tear on the sample product loaners is expected and will not incur a charge.  
  If samples are not returned within 14 days or returned in damaged or inoperable condition, Buydirectpro will charge for the cost.  
  We will check the condition of the sample products after returned to verify that it is in the agreed upon condition.
  </span><br/>

<Scrollchor to="#topOrders" className="topOrders">Back to Top</Scrollchor><br/><br/></div>

<div id="accesories"><span className="faq-header">11. Are accessories sold with products?</span><br/>
<span className="faq-text">If you select a product that requires accessories, the accessories will be 
presented on the product detail page for selection and included in your cart for ordering. </span><br/>

<Scrollchor to="#topOrders" className="topOrders">Back to Top</Scrollchor><br/><br/></div>

<div id="volume-discount"><span className="faq-header">12. Are volume discounts offered</span><br/>
<span className="faq-text">If you are interested in a multi-container order willing to make volume commitment,
 we may be able to negotiate additional discounts.  
Please contact a Buydirectpro representatives toll-free at 800-xxx-xxxx or email sales@buydirectpro.com. </span><br/>

<Scrollchor to="#topOrders" className="topOrders">Back to Top</Scrollchor><br/><br/></div>

<div id="monetary-unit"><span className="faq-header">13. What is the monetary unit for prices listed on Buydirectpro?  (USD)</span><br/>
<span className="faq-text">All prices on Buydirectpro are listed in US Dollars (USD).</span><br/>

<Scrollchor to="#topOrders" className="topOrders">Back to Top</Scrollchor><br/><br/></div>

<div id="returns"><span className="faq-header">14. Can extra product be returned?</span><br/>
<span className="faq-text"> All orders are non-cancellable, non-returnable (NCNR).</span><br/>

<Scrollchor to="#topOrders" className="topOrders">Back to Top</Scrollchor><br/><br/></div>

<div id="warranty"><span className="faq-header">15. Where can I find information regarding the manufacturer product warranty?</span><br/>
<span className="faq-text">Buydirectpro maintains a manufacturing partner pages on the site that includes a complete profile of the manufacturer 
including their product warranties.  Refer to the product detail page and either (1) click the hyperlink under the item price 
“by manufacturer name” or (2) click the manufacturer logo mark under the product images to access the manufacturer’s profile page. </span><br/>

<Scrollchor to="#topOrders" className="topOrders">Back to Top</Scrollchor><br/><br/></div>

    </TabPanel>
    <TabPanel>
      <h2>Payment and Sales Tax</h2>
      
    </TabPanel>
    <TabPanel>
      <h2>Shipping and Delivery</h2>
      
    </TabPanel>
  </Tabs>
  </div>
);
}
}
export default FAQ;
