import React from "react";
import bwlogo from '../assets/bwlogo.svg';
import insta from "../assets/instagram.svg";
import facebook from "../assets/facebook.svg";
import telephone from "../assets/telephone.svg";
import email from "../assets/email.svg";
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import {Redirect} from 'react-router-dom';
import { Container, Row, Col } from "reactstrap";
import { Route , withRouter} from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import {Link } from "react-router-dom";
import '../App.css';



function wait(ms)
{
var d = new Date();
var d2 = null;
do { d2 = new Date(); }
while(d2-d < ms);
}

class AddShippingAddress extends React.Component {
  
  
  constructor(props) {
    super(props);
    this.state = {
     AddressID: 0,
     AddressTypeID: 2, 
     AddressLine1: '',
     AddressLine2: '',
     AddressCity: '', 
     AddressState: '',
     AddressZip: ''
             
     }
    
    

    
    this.onInputchange = this.onInputchange.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
    };


   


        onInputchange(event) {

            console.log(event.target.name)
                console.log(event.target.value)
            this.setState({
              [event.target.name]: event.target.value
            });
          }


    onSubmitForm(e) {
        e.preventDefault();
      
   
    alert('Your shipping address has been added!')
    console.log('id token')
    console.log(this.state.ID_Token)
    
    console.log("stringify")
   
    let bdUserProfile = [];

    if(localStorage.getItem('bdUserProfile')){
      bdUserProfile = JSON.parse(localStorage.getItem('bdUserProfile'));
      
  }
  let profileID = '';
  profileID = bdUserProfile[0].userID;
 
  profileID = profileID.replace('auth0|', '');

  console.log("bdUserProfile")
  console.log(profileID)

    const data = new FormData();
    data.append("AddressID", this.state.AddressID);
    data.append("AddressTypeID", this.state.AddressTypeID);
    data.append("AddressLine1", this.state.AddressLine1);
    data.append("AddressLine2", this.state.AddressLine2);
    data.append("AddressCity", this.state.AddressCity);
    data.append("AddressState", this.state.AddressState);
    data.append("AddressZip", this.state.AddressZip);
    data.append("IDToken", profileID)


    console.log(data);
    fetch(`https://services.hatterasmarket.com/api/address/`, {
      method: "post",
      crossDomain:true,
     mode: "cors", // or w: "cors"ithout this line
    redirect: 'follow',
    contentType: "application/json; charset=utf-8",
    headers: {
       
        
    },
    body: data
   
    }).then((res) => res.text())
    .then((data) =>  console.log(data))
    .catch((err)=>console.log(err));

    wait(600);
    this.props.history.push('/shippingaddresses');

      }

    render() {
    
      
      
    return (
    <div>

    <span className="profile-page-header">Add Shipping Address</span><br/><br/>

    <Form className="profile-text" onSubmit={(e) => this.onSubmitForm(e)}>
    <Input type="hidden" name="AddressID" id="AddressID" value={this.state.AddressID} onChange={this.onInputchange}/>
    <Input type="hidden" name="AddressTypeID" id="AddressTypeID" value={this.state.AddressTypeID} onChange={this.onInputchange}/>
    <Row form>
        <Col md={6}>
          <FormGroup>
            <Label for="AddressLine1" className="profile-text">Address Line One</Label>
            <Input type="text" name="AddressLine1" id="AddressLine1" value={this.state.AddressLine1} onChange={this.onInputchange} />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="AddressLine2" className="profile-text">Address Line Two</Label>
            <Input type="text" name="AddressLine2" id="AddressLine2" value={this.state.AddressLine2} onChange={this.onInputchange}/>
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <Label for="AddressCity" className="profile-text">City</Label>
        <Input type="text" name="AddressCity" id="AddressCity" value={this.state.AddressCity } onChange={this.onInputchange}/>
      </FormGroup>
      <FormGroup>
        <Label for="AddressState" className="profile-text">State</Label>
        <Input type="text" name="AddressState" id="AddressState" value={this.state.AddressState } onChange={this.onInputchange}/>
      </FormGroup>
      <Row form>
        <Col md={6}>
          <FormGroup>
            <Label for="AddressZip" className="profile-text">Zip</Label>
            <Input type="text" name="AddressZip" value= {this.state.AddressZip} id="AddressZip" onChange={this.onInputchange}/>
          </FormGroup>
        </Col>
      </Row>
      <Button>Save</Button><br/><br/>
    </Form>
            </div>

)
}
}



export default withRouter(AddShippingAddress);
