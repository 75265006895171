import React, { Component } from "react";
import ProductImageBox from "./ProductImageBox";
import { formatPrice } from "../helpers";
import { formatUL } from "../helpers";
import PropTypes from "prop-types";
import AddToCard from '../assets/AddToCart.png'
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import CartModal from './CartModal.js';
import MaterialTable from 'material-table';
import SaveCart from '../assets/SaveCart.jpeg';
import SaveButton from '../assets/savebutton.jpeg';
import CancelButton from '../assets/cancel.jpeg';
import LwsLogo from '../assets/LWS-logo.jpg';
import { Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../App.css";
import Classcus from  '../assets/classcus.jpg';
import FCC from '../assets/FCC.jpg'
import EnergyStar from '../assets/Energy_star_4_0.jpg';
import cULusListed from '../assets/cULusListed.jpg';
import cruus from '../assets/cruus.jpg';
import ROHS from '../assets/ROHS.jpg';
import { separator }  from "../helpers";

import { HashLink as Link } from 'react-router-hash-link';





function wait(ms)
{
var d = new Date();
var d2 = null;
do { d2 = new Date(); }
while(d2-d < ms);
}

class ProductDetail extends Component {
    constructor(props) {
        super(props);
        
        

        this.state = {
          iteminfo: {productID: '', 
                     productName: '', 
                     productDescription: '',
                     minPrice: '',
          productPartNameList: [{productPartDetailsID : 0,
                    productPartName: ''}],
          lumensList: [{lumensValue: ''}],
          wattageList: [{wattageValue: ''}],
          colorTempList: [{colorTempValue: ''}],
          housingList: [{housingValue: ''}],
          beamAngleList: [{beamAngleValue: ''}],
          voltageList: [{voltageValue: ''}],
          dimList: [{dimmableValue: ''}],
          lifeTimeHoursList: [{lifeTimeHoursValue: ''}],
          criList: [{criValue: ''}],
          productPartsList: [{productPartDetailsID: 0,
                                productPartName: '',
                                lumens: '',
                              wattage: '',
                              cct: '',
                              housing: '',
                              beamAngle: '',
                              voltage: '',
                              lifeTimeHours: '',
                              cri: '',
                              specializedName: '',
                              dimmable: ''
                            }]
          },
        productParts: [],
        productImages: [{original : '',
                     thumbnail: ''}],
        originalProductImages: [{original : '',
                     thumbnail: ''}],
          matrixData: [{ResultSet: ''}],
          minPrice: [{startPrice: 1}],
          InitalBoxColor: '',
          LabelValue: '',
          selectedPPAId: 0,
          selectedColor: '',
          selectedPriceTypeID: 3,
          Increments: [{MinQuantity : 1}],
          showCart: false,
          showList: false,
          selectedRow: null,
          showCartForm: false,
          showListForm: false,
        listInputName: '',
        listInputDescription: '',
        cartInputName: '',
        cartInputDescription: '',
        selectedCartID: 0,
        selectedListID: 0,
        currentQuantity: 0,
        currentPrice: 0,
        fullContainerStyle: 'active-select',
        halfContainerStyle: 'inactive-select',
        sampleStyle: 'inactive-select',
        selectedProductID: 0,
        selectedProductPartID: 0,
        selectedProductSku: '',
        selectedTotalPrice: 0.00,
        selectedProductIndex: 0,
        selectedTotalQuantity: 0,
        selectedPriceMatrix: [{productPartDetailsID : 0,
            productSku: '',
            productID: 0,
            totalPrice: 0.00,
            totalQuantity: 0,
            priceID: 0,
            priceTypeID: 0,
            priceTypeCode: '',
            priceTypeDescription: '',
            specializedName: ''}],
        selectedCertifications: [{productPartDetailsID : 0,
                ul: 0,
                cULus: 0,
                es: 0,
                dlc: 0,
                fcc: 0,
                roHS: 0,
                ceC3: 0}],
        productFeatures: [{productPartDetailsID : 0,
                    sequenceNumber: 0,
                    featureText: ''
                    }],
        selectTopOptions : [{value: '',
                            label: ''}],
        selectLumenOptions : [{value: '',
                            label: ''}],
        selectWattageOptions : [{value: '',
                            label: ''}],
        selectCCTOptions : [{value: '',
                            label: ''}],
        selectHousingOptions : [{value: '',
                            label: ''}],
        selectBeamAngleOptions : [{value: '',
                            label: ''}],
        selectVoltageOptions : [{value: '',
                            label: ''}],
        selectDimOptions : [{value: '',
                            label: ''}],
        selectLTMOptions : [{value: '',
                            label: ''}],
        selectCRIOptions : [{value: '',
                            label: ''}],

                            currentTopOptions : [{value: '',
                            label: ''}],
        currentLumenOptions : [{value: '',
                            label: ''}],
        currentWattageOptions : [{value: '',
                            label: ''}],
        currentCCTOptions : [{value: '',
                            label: ''}],
        currentHousingOptions : [{value: '',
                            label: ''}],
        currentBeamAngleOptions : [{value: '',
                            label: ''}],
        currentVoltageOptions : [{value: '',
                            label: ''}],
        currentDimOptions : [{value: '',
                            label: ''}],
        currentLTMOptions : [{value: '',
                            label: ''}],
        currentCRIOptions : [{value: '',
                            label: ''}],
        cartItems: [{cartProductXrefID: 1, cartID: 1, productID: 1, productQuantity: 0, productPrice: 0, productPartDetailID: 0, productCost: 0, productName: 'Test Product', productDesc: 'Test ProductDescription', productSKU: 'XS-1234',  imageURL: '', unitQuantity: 0, priceTypeID: 0, priceTypeDescription: '' }],
        currentProductPart: {productPartDetailsID: 0,
          productPartName: '',
          lumens: '',
        wattage: '',
        cct: '',
        housing: '',
        beamAngle: '',
        voltage: '',
        lifeTimeHours: '',
        cri: '',
        specializedName: '',
        dimmable: ''
      }
      
      
        
          }
    
    //     this.submitListForm = this.submitListForm.bind(this);
    //     this.submitCartForm = this.submitCartForm.bind(this);
         this.saveItemToCart = this.saveItemToCart.bind(this);
    //     this.saveItemToList = this.saveItemToList.bind(this);
    //     this.quantityhandler = this.quantityhandler.bind(this);
  

    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    


}
static propTypes = {
    iteminfo: PropTypes.object,
    history: PropTypes.object,
    productid: PropTypes.number,
    productImages: PropTypes.array,
    currentSelectedPPAID: PropTypes.string,
    minPrice: PropTypes.object,
    productColors: PropTypes.object,
    InitalBoxColor: PropTypes.string,
    LabelValue: PropTypes.string,
    selectedPPAId: PropTypes.number,
    Increments: PropTypes.object
  };

  

  componentDidMount() {

    fetch(`https://services.hatterasmarket.com/api/products/${this.props.match.params.productid}/${this.props.match.params.productDetailID}`)  
    .then(response => response.json())
   
    .then((json) => {
        
      console.log(json)
      this.setState({ iteminfo: json })

      console.log('list')
      console.log(this.state.productParts)
      console.log('end list')
      
      const options = this.state.iteminfo.productPartNameList.map(d => ({
        "value" : d.productPartDetailsID,
        "label" : d.productPartName
      }))
      
      this.setState({selectTopOptions: options})

      const lumoptions = this.state.iteminfo.lumensList.map(d => ({
        "value" : d.lumensValue,
        "label" : d.lumensValue
      }))

      this.setState({selectLumenOptions: lumoptions})

      const wattoptions = this.state.iteminfo.wattageList.map(d => ({
        "value" : d.wattageValue,
        "label" : d.wattageValue
      }))

      this.setState({selectWattageOptions: wattoptions})

      const cctoptions = this.state.iteminfo.colorTempList.map(d => ({
        "value" : d.colorTempValue,
        "label" : d.colorTempValue
      }))

      this.setState({selectCCTOptions: cctoptions})

      const housingoptions = this.state.iteminfo.housingList.map(d => ({
        "value" : d.housingValue,
        "label" : d.housingValue
      }))

      this.setState({selectHousingOptions: housingoptions})

      const beamangleoptions = this.state.iteminfo.beamAngleList.map(d => ({
        "value" : d.beamAngleValue,
        "label" : d.beamAngleValue
      }))

      this.setState({selectBeamAngleOptions : beamangleoptions})

      const voltageoptions = this.state.iteminfo.voltageList.map(d => ({
        "value" : d.voltageValue,
        "label" : d.voltageValue
      }))

      this.setState({selectVoltageOptions : voltageoptions})


      const dimoptions = this.state.iteminfo.dimList.map(d => ({
        "value" : d.dimmableValue,
        "label" : d.dimmableValue
      }))

      this.setState({selectDimOptions : dimoptions})

      const ltmoptions = this.state.iteminfo.lifeTimeHoursList.map(d => ({
        "value" : d.lifeTimeHoursValue,
        "label" : d.lifeTimeHoursValue
      }))

      this.setState({selectLTMOptions : ltmoptions})

      const crioptions = this.state.iteminfo.criList.map(d => ({
        "value" : d.criValue,
        "label" : d.criValue
      }))

      this.setState({selectCRIOptions: crioptions})

      var first = this.state.iteminfo.productPartsList.find(element => element.productPartDetailsID === this.props.match.params.productDetailID )
      // var first = this.state.iteminfo.productPartsList[0];
      this.setState({currentProductPart: this.state.iteminfo.productPartsList.find(element => element.productPartDetailsID === this.props.match.params.productDetailID )})
      console.log("state look");
      console.log(this.state.productParts)
      console.log(this.state.iteminfo.productPartsList.find(element => element.productPartDetailsID === this.props.match.params.productDetailID ))
      console.log(this.state.iteminfo.productPartsList)
      var currentTop = [{value: first.productPartDetailsID, label: first.specializedName}];
      this.setState({currentTopOptions: currentTop} )
      
      var currentLum = [{value: first.productPartDetailsID, label: first.lumens}];
      var currentWat = [{value: first.productPartDetailsID, label: first.wattage}];
      var currentCR  = [{value: first.productPartDetailsID, label: first.cri}];
      var currentLT  = [{value: first.productPartDetailsID, label: first.lifeTimeHours}];
      var currentDim = [{value: first.productPartDetailsID, label: first.dimmable}];
      var currentBA  = [{value: first.productPartDetailsID, label: first.beamAngle}];
      var currentCCT = [{value: first.productPartDetailsID, label: first.cct}];
      var currentVolt = [{value: first.productPartDetailsID, label: first.voltage}];
    
      this.setState({currentLumenOptions: currentLum} )
      this.setState({currentWattageOptions: currentWat})
      this.setState({currentCRIOptions: currentCR})
      this.setState({currentLTMOptions: currentLT})
      this.setState({currentDimOptions: currentDim})
      this.setState({currentBeamAngleOptions: currentBA})
      this.setState({currentCCTOptions: currentCCT})
      this.setState({currentVoltageOptions: currentVolt})
    
      

    this.setState({productParts: this.state.iteminfo.productPartsList})
    this.setState({selectedProductPartID: first.ProductPartDetailsID})
    
    })
    .catch(console.log)

    fetch(`https://services.hatterasmarket.com/api/images/${this.props.match.params.productid}`)  
    .then(response => response.json())
    
    .then((json) => {
       
      this.setState({ productImages: json })
      this.setState({ originalProductImages: json })
      console.log("images:")
console.log(this.state.productImages)
      
    
    })
    .catch(console.log)
   

    fetch(`https://services.hatterasmarket.com/api/productpartcert/${this.props.match.params.productid}/${this.props.match.params.productDetailID}`)  
    .then(response => response.json())
    
    .then((json) => {
       
      this.setState({ selectedCertifications : json })
      console.log('certs')
console.log(this.state.selectedCertifications)
      
    
    })
    .catch(console.log)
 wait(100)

    

  

     fetch(`https://services.hatterasmarket.com/api/productfeature/${this.props.match.params.productid}/${this.props.match.params.productDetailID}`)  
     .then(response => response.json())
    
     .then((json) => {
        
       this.setState({ productFeatures : json })
         console.log(this.state.productFeatures)
       
     
     })
     .catch(console.log)
    
    

    
    fetch(`https://services.hatterasmarket.com/api/productpartprice/${this.props.match.params.productid}/${this.props.match.params.productDetailID}`)  
    .then(response => response.json())
   
    .then((json) => {
       
      this.setState({ selectedPriceMatrix: json })
console.log(this.state.productImages)
      
    
    })
    .catch(console.log)
    


}

saveItemToCart = (e) => {
    e.preventDefault();
    
   alert('Your item has been added to the cart!')
   console.log(this.props.match.params.productDetailID )
   console.log("ptid")

   let cart = [];
   let cartProductXrefID = 0;

   if(localStorage.getItem('cartItems')){
    cart = JSON.parse(localStorage.getItem('cartItems'));
    cartProductXrefID = cart.reduce((acc, shot) => acc = acc > shot.cartProductXrefID ? acc : shot.cartProductXrefID, 0);
}

console.log("sku");
console.log(this.state.iteminfo.productSku);
let cartID = 1

cart.push({'cartProductXrefID' : cartProductXrefID + 1, 'cartID' : cartID, 'productID': this.props.match.params.productid, 'productQuantity' : this.state.currentQuantity, 'productPrice' : this.state.selectedPriceMatrix[this.state.selectedProductIndex].totalPrice, 'productPartDetailID' : this.props.match.params.productDetailID, 'productCost': 0.00, 'productName' : this.state.iteminfo.productName, 'productDesc': this.state.iteminfo.productDescription, 'productSKU' : this.state.selectedPriceMatrix[0].productSku,  'imageURL': this.state.productImages[0].original, 'unitQuantity': this.state.selectedPriceMatrix[this.state.selectedProductIndex].totalQuantity, 'priceTypeID': this.state.selectedPriceMatrix[this.state.selectedProductIndex].priceTypeID, 'priceTypeDescription' : this.state.selectedPriceMatrix[this.state.selectedProductIndex].priceTypeDescription});
localStorage.setItem('cartItems', JSON.stringify(cart));
console.log("carts")
console.log(JSON.stringify(cart));

  
   

  }

goToCarts = event => {
    // 1. Stop the form from submitting
    event.preventDefault();
    
    
    // 3. Change the page to /store/whatever-they-entered
    this.props.history.push(`/cartdetail/1`);
  
  };

goToSearch = event => {
  // 1. Stop the form from submitting
  event.preventDefault();
  
  // 3. Change the page to /store/whatever-they-entered
  this.props.history.push(`/search/all`);

};

handleChange = (e) => {

    this.setState({ selectedProductPartID: e.value });

    var first = this.state.iteminfo.productPartsList.find(element => element.productPartDetailsID === e.value)
    // var first = this.state.iteminfo.productPartsList[0];
      var currentTop = [{value: first.productPartDetailsID, label: first.specializedName}];
      this.setState({currentTopOptions: currentTop} )
      
      var currentLum = [{value: first.productPartDetailsID, label: first.lumens}];
      var currentWat = [{value: first.productPartDetailsID, label: first.wattage}];
      var currentCR  = [{value: first.productPartDetailsID, label: first.cri}];
      var currentLT  = [{value: first.productPartDetailsID, label: first.lifeTimeHours}];
      var currentDim = [{value: first.productPartDetailsID, label: first.dimmable}];
      var currentBA  = [{value: first.productPartDetailsID, label: first.beamAngle}];
      var currentCCT = [{value: first.productPartDetailsID, label: first.cct}];
      var currentVolt = [{value: first.productPartDetailsID, label: first.voltage}];
    
      this.setState({currentLumenOptions: currentLum} )
      this.setState({currentWattageOptions: currentWat})
      this.setState({currentCRIOptions: currentCR})
      this.setState({currentLTMOptions: currentLT})
      this.setState({currentDimOptions: currentDim})
      this.setState({currentBeamAngleOptions: currentBA})
      this.setState({currentCCTOptions: currentCCT})
      this.setState({currentVoltageOption: currentVolt})

      console.log(first)
    
    

}

handleClick = (e, id) => {

    e.preventDefault();
    this.setState({ selectedProductIndex: id });

    
    if (id == 0)
    {
            this.setState({ fullContainerStyle: 'active-select'})
            this.setState({halfContainerStyle: 'inactive-select'})
            this.setState({sampleStyle: 'inactive-select'})
            this.setState({selectedPriceTypeID: 3})
        }        
            
    if (id == 1)
    {
            this.setState({ fullContainerStyle: 'inactive-select'})
            this.setState({halfContainerStyle: 'active-select'})
            this.setState({sampleStyle: 'inactive-select'})
            this.setState({selectedPriceTypeID: 2})
          
    }

    if (id == 2)
    {
        this.setState({ fullContainerStyle: 'inactive-select'});
        this.setState({halfContainerStyle: 'inactive-select'});
        this.setState({sampleStyle: 'active-select'});
        this.setState({selectedPriceTypeID: 1})
        console.log('2 -- sample')
    }

   
    
            

   console.log(this.state.selectedProductIndex)
  }

  

  toggleCartModal = () => {
    this.setState({
      showCart: !this.state.showCart
    });
  }

    render() {
      return (
        <div className="next-steps my-2">
        <Container>
            <Row>
              <Col>
                 <table>
      <tr>
      <td width="25px"></td>
      <td>
      <span align="left" className="header-span"><h2>Product Details</h2></span>
      </td>
      </tr>
      </table>
              </Col>
              </Row>
            <Row>
            <Col width="298px"><div><ProductImageBox productImages={this.state.productImages} /></div>
                <br/><br/>
                <div align="middle">
                  <table>
                    <tr>
                      <td width="50%">
                      <img src={LwsLogo} 
                  height="116px"
                  width="200px"  ></img>
                      </td>
                      <td width="50%" valign="middle" align="center">
                      Learn more about<br/> <a href="https://www.buydirectpro.com/lifewave" target="_blank" rel="noreferrer noopener">
 LifeWave Solutions</a>  
                      </td>
                    </tr>
                    </table>
                
                
                
                </div>

                
           </Col>
           <Col>
                <Container border="1">
                    <Row> 
                        <Col colSpan="2"><span className="prodName">{ this.state.iteminfo.productName } </span><br/>
                        <span className="prodSku">SKU: {this.state.selectedPriceMatrix[0].productSku}</span><br/><br/>
                        </Col>
                    </Row>
                    <Row>
                    <Col colSpan="2">
                            <span className="aslowas" valign="top">As Low As:</span><br></br>
                                         <span className="prod-price"><b>{formatPrice(this.state.iteminfo.minPrice)}&nbsp;&nbsp;each</b></span>
                        </Col>
                       
                    </Row>
                    <Row>
                    <Col colspan="2"><br/>
                        <span className="prod-desc">{ this.state.iteminfo.productDescription }</span><br/><br/>
                          <table>
                              <tr>
                                <td><b>Wattage:</b></td>
                                <td width="10px"></td>
                                <td align="right"> {separator(this.state.iteminfo.productPartsList[0].wattage)}<br/></td>
                              </tr>
                              <tr>
                                <td><b>Lumens:</b></td>
                                <td width="10px"></td>
                                <td align="right"> {separator(this.state.iteminfo.productPartsList[0].lumens)}<br/></td>
                              </tr>
                              <tr>
                                <td><b>Voltage:</b></td>
                                <td width="10px"></td>
                                <td align="right"> {this.state.iteminfo.productPartsList[0].voltage}<br/></td>
                              </tr>
                              <tr>
                                <td><b>Color Temp:</b></td>
                                <td width="10px"></td>
                                <td align="right"> {separator(this.state.iteminfo.productPartsList[0].cct)}<br/></td>
                              </tr>
                          </table>
                 
                        <br/><br/>
                        </Col>
                      </Row>
                    <Row>
                        
                        <Col colSpan="2">
                                                <span className="step-one">Select Quantity:</span><br></br>

                                                <table className="blue-box-2">
                                                    <tbody>
                                                  
                                                    <tr>
                                                            <td width="27"></td>
                                                            <td width="239" align="middle"><br/>
                                                                <button className={this.state.fullContainerStyle} onClick={(e) => {this.handleClick(e, 0)}}>40 Foot Container</button><br>
                                                                </br><br>
                                                                </br>
                                                                <button className={this.state.halfContainerStyle}  onClick={(e) => {this.handleClick(e, 1)}}>20 Foot Container</button><br>
                                                                </br><br>
                                                                </br>
                                                                <button className={this.state.sampleStyle}   onClick={(e) => {this.handleClick(e, 2)}}>Product Sample</button><br>
                                                                </br>
                                                            </td>
                                                    
                                                            <td width="57"></td>   
                                                    </tr>  
                                                    <tr>
                                                            <td width="27"></td>
                                
                                                            <td width="239" align="middle"><br/>
                                                            <table width="239">
                                                              <tbody>
                                                                <tr>
                                                                      <td><span className="prod-totals"><b>Unit Price:</b> </span></td>
                                                                      <td align="right"><span className="prod-totals">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{ formatPrice(this.state.selectedPriceMatrix[this.state.selectedProductIndex].totalPrice/this.state.selectedPriceMatrix[this.state.selectedProductIndex].totalQuantity) }</span> <br/></td>
                                                                  </tr>
                                                                  <tr>
                                                                      <td>  <span className="prod-totals">
                                                                <b>Unit Quantity:</b>  </span></td>
                                                                      <td align="right"><span className="prod-totals">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{separator(this.state.selectedPriceMatrix[this.state.selectedProductIndex].totalQuantity)} <br/>
                                                                </span></td>
                                                                  </tr>
                                                                  <tr>
                                                                      <td>  <span className="prod-totals">
                                                                <b>Total Price:</b> </span></td>
                                                                      <td align="right"><span className="prod-totals">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{formatPrice(this.state.selectedPriceMatrix[this.state.selectedProductIndex].totalPrice)}
                                                                
                                                                </span></td>
                                                                  </tr>
                                                           
                                                             </tbody>
                                                             </table><br/>
                                                                {/* <img src={AddToCard} onClick={(e) => {this.saveItemToCart(e)}}></img><br/><br/> */}
                                                                <button className="submit-cart"  onClick={(e) => {this.saveItemToCart(e)}}><b>Add To Cart</b></button><br/><br/>
                                                            </td>
                                                    
                                                            <td width="57"></td>   
                                                    </tr>  
                                                   {/* <tr>
                                                            <td width="27"></td>
                                                        
                                                            <td width="63" > </td>
                                                            <td colSpan="2" valign="top" align="left" className="product-total-price">{formatPrice(this.state.selectedPriceMatrix[this.state.selectedProductIndex].totalPrice)}</td>
                                                            <td width="27"></td>   
                                                    </tr>     
                                                    <tr>
                                                            <td width="27"></td>
                                                            
                                                            <td width="63" > </td>
                                                            <td width="134" valign="top" className="unit-quantity">Unit Qty.</td>
                                                            <td width="152" valign="top" className="unit-quantity">Unit Cost</td>
                                                            <td width="27"></td>   
                                                    </tr>
                                                     <tr>
                                                            <td width="27"></td>
                                                            
                                                            <td width="63" > </td>
                                                            <td width="134" valign="top" className="unit-quantity">{this.state.selectedPriceMatrix[this.state.selectedProductIndex].totalQuantity}</td>
                                                        <td width="152" valign="top" className="unit-quantity">{ formatPrice(this.state.selectedPriceMatrix[this.state.selectedProductIndex].totalPrice/this.state.selectedPriceMatrix[this.state.selectedProductIndex].totalQuantity) }</td>
                                                            <td width="27"></td>   
                                                    </tr> */}
                                                    {/* <tr>
                                                    <td width="27"></td>
                                                        <td colspan="4"><br></br><img src={AddToCard} onClick={(e) => {this.saveItemToCart(e)}}></img><br/><br/></td>
                                                    </tr> */}
                                                    </tbody>
                                                </table>
                                                <br></br>

                                                
                        </Col>

                    </Row>
                </Container>
          
           </Col>
            </Row>
        
       
           
            <Row>
                <Col>
                <Tabs>
                        <TabList>
                            <Tab>Product Details</Tab>
                            <Tab>Technical Specifications</Tab>
                        </TabList>

    <TabPanel>
    <br/><br/>
          <Row>
                  <Col>
                    <span className="lower-header"><b>Product Highlights</b></span><br></br><br></br>
                    <span className="lower-text">{this.state.iteminfo.productDescription}</span>
                    </Col>
                <Col>
                  <span className="lower-header"><b>Key Features</b></span><br></br><br></br>
                  <ul className="lower-text">
                  {
                      this.state.productFeatures.map((item) => <li key={item.sequenceNumber}>{item.featureText}</li>)
                  }
                  </ul>
                  <br/><br></br>
                </Col>
            </Row>
            
            <Row>
                  <Col>
                  <span className="lower-header"><b>Certfications</b></span><br></br><br></br>
                   
                  {this.state.selectedCertifications[0].ul == 1 &&
                    <span className="lower-text">UL Certified<br></br></span>
                     }
                   {this.state.selectedCertifications[0].cULus == 1 &&
                    <span>cULus Listed<br/></span>
                     }

                {this.state.selectedCertifications[0].es == 1 &&
                    <span>Energy Star Certified<br/><br/></span>
                     }  
                {this.state.selectedCertifications[0].dlc == 1  &&
                    <span className="lower-text">DLC Certified<br></br></span>
                     }  
                {this.state.selectedCertifications[0].fcc == 1  &&
                    <span>FCC Certified<br/></span>
                     }  
                {this.state.selectedCertifications[0].roHS == 1  &&
                    <span>ROHS Certified<br/><br/></span>
                     }  
                {this.state.selectedCertifications[0].ceC3 == 1  &&
                    <span className="lower-text">CeC3 Certified<br></br></span>
                     } 
                    
                    
                  </Col><Col>
                  
                  <span className="lower-header"><b>Documents</b></span><br></br><br></br>  
                  </Col>
              </Row>
        
    </TabPanel>
    <TabPanel>
      <br/><br/>
      <span className="lower-header"><b>Technical Specifications</b></span><br/><br/>
    
                        <table >
                                                    <tbody>
                                                        
                                                    
                                                        <tr>
                                                            <td className="form-labels-white" width="10" height="36" ></td>
                                                            <td  className="form-labels-white" width="276"><b>Lumens</b></td>
                                                            <td  className="form-labels-white" width="16" height="36"> </td>
                                                            <td  className="form-labels-white" width="276" height="36">{this.state.iteminfo.productPartsList[0].lumens}</td>
                                                            <td  className="form-labels-white" width="16" height="36"> </td>
                                                            
                                                        </tr>
                                                        <tr>
                                                        <td width="10" height="36" className="form-labels-gray"></td>
                                                            <td className="form-labels-gray" width="276" height="36">
                                                                <b>Wattage</b>
                                                            </td>
                                                            <td className="form-labels-gray" width="16" height="36"> </td>
                                                            <td className="form-labels-gray" width="276" height="36">
                                                            {this.state.iteminfo.productPartsList[0].wattage}
                                                            </td>
                                                            <td className="form-labels-gray" width="16" height="36"> </td>
                                                        
                                                        </tr>
                                                        <tr>
                                                        <td className="form-labels-white" width="10" height="36"> </td>
                                                        <td className="form-labels-white"  width="276" height="36"><b>Color Temp</b></td>
                                                        <td className="form-labels-white"  width="16" height="36"> </td>
                                                            <td className="form-labels-white" width="276" height="36">{this.state.iteminfo.productPartsList[0].cct}</td>
                                                            <td className="form-labels-white" width="16" height="36"> </td>
                                                        </tr>
                                                        <tr>
                                                        <td className="form-labels-gray" width="10" height="36"> </td>
                                                            <td width="276" height="36" className="form-labels-gray">
                                                              <b>Beam Angle</b>
                                                            </td>
                                                            <td className="form-labels-gray" width="16" height="36"> </td>
                                                            <td className="form-labels-gray" width="276" height="36">
                                                            {this.state.iteminfo.productPartsList[0].beamAngle}
                                                            </td>
                                                            <td className="form-labels-gray"width="16" height="36"></td>
                                                        </tr>

                                                        <tr>
                                                            <td className="form-labels-white" width="10" height="36"></td>
                                                            
                                                            <td className="form-labels-white" width="276" height="36"><b>Voltage</b></td>
                                                            <td className="form-labels-white" width="16" height="36"> </td>
                                                            <td className="form-labels-white" width="276" height="36">{this.state.iteminfo.productPartsList[0].voltage}</td>
                                                            <td className="form-labels-white" width="16" height="36"></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="form-labels-gray" width="10" height="36"></td>
                                                              
                                                            <td className="form-labels-gray" width="276" height="36" align="left">
                                                              <b>Dimmable</b>    
                                                            </td>
                                                            <td className="form-labels-gray" width="16" height="36"> </td>
                                                            <td className="form-labels-gray" width="276" height="36">
                                                            {this.state.iteminfo.productPartsList[0].dimmable}
                                                            </td>
                                                            <td className="form-labels-gray" width="16" height="36"></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="form-labels-white" width="10" height="36"></td>
                                                            <td className="form-labels-white" width="276" height="36" ><b>Lifetime Hours</b></td>
                                                            <td className="form-labels-white" width="16" height="36"> </td>
                                                            <td width="276" height="36" className="form-labels-white">{this.state.iteminfo.productPartsList[0].lifeTimeHours}</td>
                                                            <td className="form-labels-white" width="16" height="36"> </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="form-labels-gray" width="10" height="36"></td>
                                                            <td className="form-labels-gray"  width="276" height="36">
                                                              <b>CRI</b>
                                                            </td>
                                                            <td className="form-labels-gray"  width="16" height="36"> </td>
                                                            <td className="form-labels-gray"  width="276" height="36" >
                                                            {this.state.iteminfo.productPartsList[0].cri}
                                                            </td>
                                                            <td className="form-labels-gray"  width="16" height="36"> </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="form-labels-white" width="10" height="36"></td>
                                                            <td className="form-labels-white" width="276" height="36" ><b>Country of Origin</b></td>
                                                            <td className="form-labels-white" width="16" height="36"> </td>
                                                            <td width="276" height="36" className="form-labels-white">China</td>
                                                            <td className="form-labels-white" width="16" height="36"> </td>
                                                        </tr>
                                                        
                                                        <tr>
                                                            <td colSpan="5" height="25"></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <br/><br/>
    </TabPanel>
   
  </Tabs>
                </Col>
            </Row>
            </Container>
          
            </div>
        );
    }
  }
  
  export default ProductDetail;