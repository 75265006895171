import React, { Component } from "react";
import bwlogo from '../assets/bwlogo.svg';
import { Container, Row, Col } from "reactstrap";

import { Button, Form, FormGroup, Label, Input, FormText  } from 'reactstrap';




class CommPreferences extends Component {

    state = {
        commPreferences: {  PhoneOptIn: false,
                        EmailOptIn: false, 
                        TextOptIn: false
                      },
        PhoneOptIn: false,
        EmailOptIn: false, 
        TextOptIn: false,
        EmailAddress: '',
        VoicePhone: '',
        TextPhone: ''
               
       }
    constructor(props) {
        super(props);
        
        this.onInputchange = this.onInputchange.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);
        };

        onInputchange(event) {

            console.log(event.target.name)
                console.log(event.target.value)
            this.setState({
              [event.target.name]: event.target.value
            });
          }


    onSubmitForm(e) {
        e.preventDefault();
      
   
    alert('Your preferences have been updated')
    console.log(this.state.FirstName)

    this.props.history.push('/myprofile');

    // fetch(`http://localhost:8900/api/useraccess/`, {
    //   method: "post",
    //   crossDomain:true,
    //   mode, // or w: "cors"ithout this line
    // redirect: 'follow',
    // headers: {
    //     'Content-Type':'application/x-www-form-urlencoded',
    //     "Content-type": "application/json",
    //     "Accept": "application/json",
    //     "Accept-Charset": "utf-8"
        
    // },

    
    // body: JSON.stringify({
    //     ID_Token: '60204042b9df33006b8e27a3',
    //     FirstName: this.state.FirstName,
    //     LastName:  this.state.LastName,
    //     AddressOne: this.state.AddressOne,
    //     AddressTwo: this.state.AddressTwo,
    //     City: this.state.City,
    //     State: this.state.State,
    //     Zip: this.state.Zip

    // })
   
    // }).then((res) => res.text())
    // .then((data) =>  console.log(data))
    // .catch((err)=>console.log(err));

    
    

      }

   
  render() {
    return (
  <div className="text-left">
    <span className="profile-page-header">Communication Preferences</span><br/><br/>


<Form className="profile-text" onSubmit={(e) => this.onSubmitForm(e)}>
<Row form>
<Col md={4}>
<br></br>
  <FormGroup>
  <Input type="checkbox" name="EmailOptIn" id="EmailOptIn" value={this.state.EmailOptIn} onChange={this.onInputchange}/>
    <Label for="EmailOptIn" className="profile-text">Email</Label>
  </FormGroup>
  </Col>
  <Col md={4}>
  <FormGroup>
  <Label for="EmailAddress" className="profile-text">Email Address</Label>
  <Input type="text" name="EmailAddress" id="EmailAddress" value={this.state.EmailAddress} onChange={this.onInputchange}/>
    
  </FormGroup>
  </Col>
  
  </Row>
  <Row form>
  <Col md={4}>
  <FormGroup>
  <br></br>
  <Input type="checkbox" name="TextOptIn" id="TextOptIn" value={this.state.TextOptIn} onChange={this.onInputchange}/>
    <Label for="TextOptIn" className="profile-text">Text</Label>
  </FormGroup>
  </Col>
  <Col md={4}>
  <FormGroup>
  <Label for="TextOptIn" className="profile-text">Text Phone</Label>
  <Input type="text" name="TextPhone" id="TextPhone" value={this.state.TextPhone} onChange={this.onInputchange}/>
  </FormGroup>
    
  </Col>
  </Row>
  <Row form>
  <Col md={4}>
  <br></br>
  <FormGroup>
  <Input type="checkbox" name="PhoneOptIn" id="PhoneOptIn" value={this.state.PhoneOptIn} onChange={this.onInputchange}/>
    <Label for="PhoneOptIn" className="profile-text">Voice Phone</Label>
  </FormGroup>
  </Col>
  <Col md={4}>
  <FormGroup>
  <Label for="VoicePhone" className="profile-text">Phone</Label>
  <Input type="Text" name="VoicePhone" id="VoicePhone" value={this.state.VoicePhone} onChange={this.onInputchange}/>
    
  </FormGroup>
  </Col>
  </Row>
  <Button>Save</Button><br/><br/>
</Form>
  </div>
);
}
}
export default CommPreferences;
