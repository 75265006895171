import React, { Component } from "react";
import bwlogo from '../assets/bwlogo.svg';
import { Container, Row, Col } from "reactstrap";
import insta from "../assets/instagram.svg";
import facebook from "../assets/facebook.svg";
import telephone from "../assets/telephone.svg";
import email from "../assets/email.svg";
import howToBuyHeader from "../assets/buyingprocessHeader.png";
import buySearch from "../assets/BuySearch.png";
import buyCheckOut from "../assets/buyCheckout.png";
import buyManufacturing from "../assets/buyManufacturing.png";
import buyFreeShipping from "../assets/buyFreeshipping.png";
import buyDelivery from "../assets/buyDelivery.png";
import buyCustomerService from "../assets/buyCustomerService.png";




class HowToBuy extends Component {

   
  render() {
    return (
  <div className="text-center">
    
      <Container>
        <Row>
          <Col colSpan="2" align="middle">
            <img src={howToBuyHeader} width="100%"/>
          </Col>
        </Row>
        <Row>
          <Col colspan="2" className="spacer-text"> 
          </Col>
        </Row>
        <Row>
          <Col className="process-header" colSpan="2" align="middle" height="300px">	Our buying process makes it easy to . . .  </Col>
          
        </Row>
        <Row>
          
          <Col colspan="2" className="spacer-text"> 
          </Col>
        </Row>
        <Row>
          <Col><img 
                src={buySearch}/></Col>
          <Col height="225" className="process-text-left"><span><b>Search</b><br/>Search and select products from our certified manufacturer partners.  Easily check current prices and review specifications.</span></Col>
        </Row>
        <Row>
          <Col colspan="2" className="spacer-text"> 
          </Col>
        </Row>
        <Row>
          <Col align="middle" >
            <img 
            src={buyCheckOut}/></Col>
            <Col className="process-text-left"><span><b>Checkout</b><br/>Once products are selected in the cart, it’s simple to checkout and pay using our secure ACH funds transfer.</span></Col>
        
        
        </Row>
        <Row>
          <Col colspan="2" className="spacer-text"> 
          </Col>
        </Row>
        <Row>
          <Col align="middle"> <img 
          src={buyManufacturing}/><br/><br/><br/></Col>
          <Col className="process-text-left"><span><b>Manufacturing</b><br/>Build to Order.  Customization Options Order Status</span></Col>
        </Row>
        <Row>
          <Col colspan="2" className="spacer-text"> 
          </Col>
        </Row>
        <Row>
        <Col align="middle" > <img 
             src={buyFreeShipping}/><br/><br/><br/></Col>
         <Col className="process-text-left"><b>Free Shipping</b><br/>Free Shipping to Lower 48 United States and Canada.  Tracking and Updates</Col>
         
        
        </Row>
        <Row>
          
          <Col colspan="2" className="spacer-text"> 
          </Col>
        </Row>
        <Row>
          <Col align="middle">
                               <img 
                               src={buyDelivery}/><br/><br/><br/><br/></Col>
          <Col className="process-text-left"><b>Delivery</b><br/>Build to Order.  Customization Options Order Status</Col>
        </Row>
        <Row>
          <Col align="middle" > <img 
            
                                src={buyCustomerService}/><br/><br/><br/><br/></Col>
          <Col className="process-text-left"><b>Customer Service</b><br/>Free Shipping to Lower 48 United States and Canada.  Tracking and Updates</Col>
       
        
        </Row>
        <Row>
          
          <Col colspan="2" className="spacer-text"> 
          </Col>
        </Row>
      </Container>
  </div>
);
}
}
export default HowToBuy;
