export function formatPrice(price) {
    return (Number(Math.round(price+'e'+2)+'e-'+2)).toLocaleString("en-US", {
      style: "currency",
      currency: "USD"
    });
  }

  export function formatNumber(price) {
    return (Number(Math.round(price+'e'+2)+'e-'+2));
  }
  export function format2decimal(num) {
    return (Math.round(num * 100) / 100).toFixed(2);

  }
  export function separator(numb) {
    var str = numb.toString().split(".");
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return str.join(".");
}
  export function formatUL(num) {
    if(num === 1)
    {
      return("UL")
    }
  }