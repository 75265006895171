import React from "react";
import bwlogo from '../assets/bwlogo.png';
import { Row, Col } from "reactstrap";
import insta from "../assets/instagram.svg";
import facebook from "../assets/facebook.svg";
import telephone from "../assets/telephone.svg";
import email from "../assets/email.svg";
import {Link } from "react-router-dom";
import logo from '../assets/logo2.png';



const Footer = () => (
  <div className="new-footer p-4 text-center">
     <Row>
      <Col>
      
      </Col>
      <Col align="left">
      <img
      src={logo}
></img>
      </Col>
      <Col>
      <span className="appFooterBold2"> Contact Us</span><br></br>
      <span className="appFooterSmaller2">
      1-866-304-1550<br/>

       <Link className="appFooterSmaller2"
            to='#'
            onClick={(e) => {
                window.location.href = 'mailto:sales@buydirectpro.com';
                e.preventDefault();
            }}
        >
            sales@buydirectpro.com<br/><br/><br></br>
        </Link>

      </span>
      </Col>
      <Col>
      
      </Col>
      </Row>
      {/*
      <Col align="left">
      <span className="appFooterBold"> GET TO KNOW US</span><br></br><br></br>
    <span className="appFooterRegular">
    <Link to="about" className="appFooterRegular">About Us</Link><br/>
    <Link to="/whybuy" className="appFooterRegular">Why BuyDirectPro?</Link><br></br>
    </span>
      </Col>
      <Col align="left">
      <span className="appFooterBold"> CUSTOMER SERVICE</span><br/><br/>
    <span className="appFooterRegular">
    <Link to="/faq" className="appFooterRegular">Help Center and FAQ</Link> <br/>
    <Link to="contactus" className="appFooterRegular">Contact Us</Link> <br/>
    </span>
      </Col>
      <Col align="left">
      <span className="appFooterBold"> ACCOUNT</span><br/><br/>
    <span className="appFooterRegular">
    Order Status <br/>
    <Link to="/cart" className="appFooterRegular">My Cart</Link><br/>
    </span>
      </Col>
      <Col align="left">
      <span className="appFooterBold"> FOLLOW US ON</span><br/><br/>

      <span className="appFooterSmaller">
      <img
      src={insta}>
      </img>
      &nbsp;&nbsp;
      <img
      src={facebook}>
      </img><br/><br/><br/>
      <img
      src={telephone}>
      </img>&nbsp;&nbsp;1-866-304-1550<br/>
      <img
      src={email}></img>&nbsp;&nbsp;
       <Link className="appFooterSmaller"
            to='#'
            onClick={(e) => {
                window.location.href = 'mailto:sales@buydirectpro.com';
                e.preventDefault();
            }}
        >
            sales@buydirectpro.com
        </Link>
  

    
    </span>
      </Col>
   
  

    </Row> */}

    <Row>
      <Col span="5">
      <span className="appFooterRegular2">
      &copy;2022 BuyDirectPro &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Link to="/privacy"  className="appFooterRegular2">Privacy Policy</Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Link to="/termsuse"  className="appFooterRegular2">Terms of Use</Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      </Col>
    </Row>
   
      
  </div>
);

export default Footer;
