import React, { Component } from "react";
import bwlogo from '../assets/bwlogo.svg';
import insta from "../assets/instagram.svg";
import facebook from "../assets/facebook.svg";
import telephone from "../assets/telephone.svg";
import email from "../assets/email.svg";
import "../App.css";
import { Container, Row, Col } from "reactstrap";
import AboutUsHeader from "../assets/AboutUsHeader.png";
import AboutUsSimplicity from '../assets/AboutUsSimplicity.png';
import AboutUsBTO from '../assets/AboutUsBTO.png';
import AboutUsFreeShipping from '../assets/AboutUsFreeShipping.png';
import AboutUsCustomerService from '../assets/AboutUSCustomerService.png';
import NoHassle from "../assets/No Hassle.png";
import AdvancedTechnology from "../assets/Advanced Technology.png";
import WeDeliverValue from "../assets/We Deliver Value.png";
import BestPrices from '../assets/Best Prices.png';
import EliminateMarkup from '../assets/Eliminate Markup.png';
import CertifiedSuppliers from '../assets/Certified Suppliers.png';
import BuildToOrder from '../assets/BuildToOrder.png';
import ProductBundles from '../assets/Product Bundles.png';
import GlobalLogistics from '../assets/Global Logistics.png';





class FlexibleOptions extends Component {

   
  render() {
    return (
<div className="text-center">
    
    <Container>
      <Row>
        <Col colSpan="3" align="left">
          <h2>Flexible Product Options</h2>
        </Col>
      </Row>
      {/* <Row>
        <Col colspan="3" className="spacer-text"> 
        </Col>
      </Row>
      <Row>
        <Col className="frontPageHeaders" colSpan="3" align="middle" height="300px"> </Col>
        
      </Row>
      <Row>
        
        <Col colspan="3" className="spacer-text"> 
        </Col>
      </Row>
      
        
        <Row>
        
        <Col colspan="3" className="spacer-text"> 
        </Col>
      </Row> */}
      <Row>
    <Col border="1"><br/>
    <img src={BuildToOrder}/><br/><br/><br/>
      <span className="subText2"><b>Build To Order</b><br/><br/>BuyDirectPro certified manufacturer partners build goods at the time of order ensuring products are produced with the latest designs and components.  </span>
    </Col>
    <Col><br/>
    <img src={ProductBundles}/><br/><br/>
    <span className="subText2"><br/><b>Product Bundles</b><br/><br/>Product bundles allow for different products to be combined into a single shipment.  If your project requires various products, efficiently combine within a shipping container and deliver directly to your jobsite. </span>
    </Col>
    
    <Col><br/><br/>
    <img src={GlobalLogistics} /><br/><br/>
    <span className="subText2"><b>Global Logistics </b><br/><br/>We manage production and transportation around the world to control all logistical aspects of your order from the factory to your destination of choice.  </span>
    </Col>
  </Row>
  <Row>
        
        <Col colspan="2" className="spacer-text"> 
        </Col>
      </Row>
          </Container>
</div>
);
}
}
export default FlexibleOptions;