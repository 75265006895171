import React, { Component } from "react";
import bwlogo from '../assets/bwlogo.svg';
import { Row, Col } from "reactstrap";
import insta from "../assets/instagram.svg";
import facebook from "../assets/facebook.svg";
import telephone from "../assets/telephone.svg";
import email from "../assets/email.svg";




class TermsOfUse extends Component {

   
  render() {
    return (
  <div className="p-3 text-left">
    <b>TERMS OF USE</b><br></br><br></br> 

    Last Updated: July 1, 2021
    <br></br><br></br> 
Welcome to Blue Pelican Software LLC, d/b/a BuyDirectPro (“BDP”), and its website, buydirectpro.com (“Site”). Please read the following terms and conditions (“Terms of Use”) that govern your use of and/or purchase of items through Site as they constitute a legally binding agreement between you and BDP. By clicking “Accepted and Agreed”, you agree to be bound by this Terms of Use, as may be amended from time to time by BDP. 
<br></br><br></br>
<b>INTELLECTUAL PROPERTY RIGHTS </b> 
<br></br><br></br>
Other than the content you own, under this Terms of Use, BDP and/or its licensors own all the intellectual property rights and materials contained on Site. 
<br></br><br></br>
You are granted a limited license only for the purpose of viewing and purchasing items contained on Site. 
<br></br><br></br>
<b>RESTRICTIONS </b> 
<br></br><br></br>
You are specifically restricted from all of the following: 
<br></br><br></br>
<li>publishing any Site material in any other media, or platform; </li>

<li>selling, sublicensing and/or otherwise commercializing any Site material, or items listed on Site; </li>

<li>publicly performing and/or showing any Site material; </li>

<li>using Site in any way that is or may be damaging to Site; </li>

<li>using Site in any way that impacts user access to Site; </li>

<li>using Site contrary to applicable laws and regulations, or in any way may cause harm to Site, or to any person or business entity; </li>

<li>engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to Site; </li>

<li>reverse engineering, decompiling or disassembling, tampering with, or bypassing any security associated with Site, or any other software owned by BDP, whether in whole or in part; and </li>

<li>using Site to engage in any advertising or marketing.</li>
<br></br><br></br>
Certain areas of Site are restricted from being accessed by you and BDP may further restrict access by you to any areas of Site, at any time, in absolute discretion. Any user ID and password you may have for Site are confidential and you must maintain confidentiality as well. If you feel your user ID and/or password have been compromised, it is your responsibility to submit written notice to BDP. 
<br></br><br></br>
<b>PRIVACY</b> 
<br></br><br></br>
Please review our Privacy Policy located at https://www.buydirectpro.com/privacy, which also governs your use of Site, to understand our practices. 
<br></br><br></br>

<b>NO WARRANTIES </b> 
<br></br><br></br>
Site is provided by BDP “as-is,” with all faults, and BDP expresses no representations or warranties of any kind related to Site or the content contained on Site. Also, nothing contained on Site shall be interpreted as advising you. 
<br></br><br></br>
<b>LIMITATION OF LIABILITY </b> 
<br></br><br></br>
In no event shall BDP, nor any of its officers, directors, employees, and/or affiliates be held liable for anything arising out of or in any way connected with your use of Site, whether or not such liability, is under contract. BDP, including its officers, directors, employees and/or its affiliates shall not be held liable for any indirect, consequential or special liability arising out of or in any way related to your use of Site.   
<br></br><br></br>

<b>INDEMNIFICATION </b> 
<br></br><br></br>
<b>You hereby indemnify to the fullest extent BDP from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of this Terms of Use. </b>
<br></br><br></br>
<b>ELECTRONIC COMMUNICATIONS</b> 
<br></br><br></br>
When you use Site, or send e-mails, text messages, and other communications from your desktop or mobile device to Site, you may be communicating with BDP electronically. You consent to receive communications from BDP electronically, such as e-mails, texts, mobile push notices, or notices and messages on Site or through the other Site services and you can retain copies of these communications for your records. You agree that all agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.  
<br></br><br></br>
<b>COPYRIGHT</b> 
<br></br><br></br>
All content included in or made available through Site, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software is the property of Site, or the manufacturer, supplier, and/or seller of items on Site (“Product Supplier”) and protected by United States and international copyright laws. The compilation of all content included in or made available through Site is the exclusive property of BDP and protected by U.S. and international copyright laws. 
<br></br><br></br>

<b>LICENSE AND ACCESS</b> 
<br></br><br></br>

Subject to your compliance with this Terms of Use, and your payment of any applicable fees, BDP grants you a limited, non-exclusive, non-transferable, non-sublicensable license to access and make use of Site and its services. This license does not include:  any collection and use of any item listings, descriptions, or prices; any derivative use of Site or its contents; any downloading, copying, or other use of account information for the benefit of any third party; or any use of data mining, robots, or similar data gathering and extraction tools. All rights not expressly granted to you in this Terms of Use are reserved and retained by BDP or its licensors, Product Suppliers, publishers, rightsholders, or other content providers. No Site content, data or information may be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without express written consent of Site. You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of Site, or of the Product Suppliers on Site without express written consent. You may not use any meta tags or any other "hidden text" utilizing BDP’s name or trademarks without the express written consent of BDP. You may not misuse the Site, or any services offered by Site. You may use Site only as permitted by law. The licenses granted by BDP to you to use Site may be terminated by BDP immediately if you do not comply with this Terms of Use. 
<br></br><br></br>

<b>YOUR ACCOUNT</b> 
<br></br><br></br>
You may need your Site account to use certain Site services, and you may be required to be logged in to the account and have a valid payment method associated with it. You can set your payment options through your account. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your account, and you agree to accept responsibility for all activities that occur under your account or password. Site reserves the right to refuse service, terminate accounts, terminate your rights to use Site or any of its services, remove or edit content, or cancel orders in its sole discretion. 1
<br></br><br></br>

<b>RISK OF LOSS</b> 
<br></br><br></br>
All purchases of items through Site are made pursuant to a sales contract between you and Product Supplier of the applicable purchased item(s). By purchasing a Product Supplier’s items through Site, you acknowledge and agree that BDP is not responsible in any way for the quality, workmanship, delivery, or any other aspect of the items purchased. You acknowledge and agree that any remedy at law, or at contract, you have, or may have regarding your purchase of Product Supplier’s  items is solely between you and Product Supplier.  
<br></br><br></br>

<b>RETURNS, REFUNDS AND TITLE</b> 
<br></br><br></br>

BDP does not take title to returned product items purchased through Site. You acknowledge and agree that any issues you have regarding return, refunds, and/or title of items purchased through Site shall be solely between you and Product Supplier of the purchased items. Once your order is placed on Site, you have 48 hours to cancel the transaction.   Any refund, return, quality, and/or title issues of any product items purchased on Site must be made directly to Product Supplier(s) providing said item(s).  
<br></br><br></br>


<b>PRODUCT ITEM DESCRIPTIONS</b> 
<br></br><br></br>

BDP attempts to be as accurate as possible with product item descriptions on Site. However, BDP does not warrant that product item descriptions or other content on Site is accurate, complete, reliable, current, or error-free.  
<br></br><br></br>

<b>PRICING</b> 
<br></br><br></br>

"List Price" means the suggested retail price of an item as provided by a Product Supplier. With respect to items sold on Site, we cannot confirm the price of an item until you order. Despite our best efforts, some of the items in our catalog may be mispriced. If the correct price of an item sold through Site is higher than the stated price, we will not process your order, and will either contact you for instructions before shipping or cancel your order and notify you of such cancellation.   
<br></br><br></br>

<b>SANCTIONS AND EXPORT POLICY</b> 
<br></br><br></br>

You may not use Site or any of its services if you are the subject of U.S. sanctions or of sanctions consistent with U.S. law imposed by the governments of the country where you are using Site. You must comply with all U.S. or other export and re-export restrictions that may apply to goods, software (including Site software), technology, and services.   
<br></br><br></br>


<b>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY </b>
<br></br><br></br>
BDP AND ALL INFORMATION CONTAINED ON SITE, CONTENT, MATERIALS, PRODUCT ITEMS (INCLUDING SOFTWARE) AND OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH SITE ARE PROVIDED BY BDP "AS IS" AND "AS AVAILABLE" BASIS, UNLESS OTHERWISE SPECIFIED IN WRITING.  BDP MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF SITE, OR THE INFORMATION, CONTENT, MATERIALS, PRODUCT ITEMS (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH SITE, UNLESS OTHERWISE SPECIFIED IN WRITING. YOU EXPRESSLY AGREE THAT YOUR USE OF  SITE IS AT YOUR SOLE RISK. 
<br></br><br></br>

TO THE FULL EXTENT PERMISSIBLE BY LAW,  BDP DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.  BDP DOES NOT WARRANT THAT SITE INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH SITE, SITE'S AND/OR BDP’S SERVERS OR ELECTRONIC COMMUNICATIONS SENT FROM THROUGH SITE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. TO THE FULL EXTENT PERMISSIBLE BY LAW, BDP WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF SITE, OR FROM ANY INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH SITE, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE SPECIFIED IN WRITING. 
<br></br><br></br>

<b>Governing Law & Jurisdiction </b>
<br></br><br></br>
This Terms of Use will be governed by and interpreted in accordance with the laws of the State of North Carolina, and you submit to the non-exclusive jurisdiction of the state and federal courts located in North Carolina for the resolution of any disputes. 
<br></br><br></br>

<b>DISPUTES </b>
<br></br><br></br>
<b>Any dispute or claim relating in any way to your use of Site, will be resolved by binding arbitration, by a panel of three arbiters, rather than in court</b>, except that you may assert claims in small claims court if your claims qualify. The Federal Arbitration Act and federal arbitration law apply to this agreement. 
<br></br><br></br>
 
 <b>There is no judge or jury in arbitration, and court review of an arbitration award is limited. However, an arbitration panel can award on an individual basis the same damages and relief as a court (including injunctive and declaratory relief or statutory damages), and must follow the terms of these Conditions of Use as a court would. </b>
 <br></br><br></br>
 To begin an arbitration proceeding, you must send a letter requesting arbitration and describing your claim to 8376 Six Forks Rd Suite 202  
Raleigh, NC 27615-5095. The arbitration will be conducted by the American Arbitration Association (AAA) under its rules, including the AAA's Supplementary Procedures for Consumer-Related Disputes. The AAA's rules are available at www.adr.org or by calling 1-800-778-7879. Payment of all filing, administration and arbitrator fees will be governed by the AAA's rules. You may choose to have the arbitration conducted by telephone, based on written submissions, or in person in the county where you live or at another mutually agreed location. 
<br></br><br></br>
<b>We each agree that any dispute resolution proceedings will be conducted only on an individual basis and not in a class, consolidated or representative action.</b> If for any reason a claim proceeds in court rather than in arbitration <b>we each waive any right to a jury trial.</b> We also both agree that you or we may bring suit in court to enjoin infringement or other misuse of intellectual property rights. 
<br></br><br></br><b>
ADDITIONAL SITE SOFTWARE TERMS</b> 
<br></br><br></br>
The following terms apply to any BDP software (including any updates or upgrades to Site software) and any related documentation we make available to you in connection with Site (“Site Software"). 
<br></br><br></br>
1. <b>Use of Site Software.</b> You may use Site Software solely for purposes of enabling you to use Site, and as permitted by this Terms of Use. You may not incorporate any portion of the Site Software into other programs or compile any portion of it in combination with other programs, or otherwise copy (except to exercise rights granted in this section), modify, create derivative works of, distribute, assign any rights to, or license the Site Software in whole or in part. All software used in Site is the property of BDP or its software suppliers and is protected by United States and international copyright laws. 
<br></br><br></br>
2. <b>Use of Third-Party Services.</b> When you use Site, you may also be using the services of one or more third parties, such as a wireless carrier or a mobile software provider. Your use of these third-party services may be subject to the separate policies, terms of use, and fees of these third-parties. 
<br></br><br></br>
 

3. <b>Updates.</b> We may offer automatic or manual updates to Site, and/or Site Software at any time and without notice to you. 
<br></br><br></br>
4. <b>Government End Users.</b> If you are a U.S. Government end user, we are licensing the use of Site and/or Site Software to you as a "Commercial Item" as that term is defined in the U.S. Code of Federal Regulations (see 48 C.F.R. § 2.101), and the rights we grant you to use Site and/or Site Software are the same as the rights we grant to all others under this Terms of Use. 
<br></br><br></br>
5. <b>Conflicts.</b> In the event of any conflict between this Terms of Use and any other Site or third-party terms applicable to any portion of Site and/or Site Software, such as open-source license terms, such other terms will control as to that portion of Site Software and to the extent of the conflict. 
<br></br><br></br>
<b>SEVERABILITY </b> 
<br></br><br></br>
If any provision of this Terms of Use is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein. 
<br></br><br></br>
<b> VARIATION OF TERMS  </b>
<br></br><br></br>
BDP is permitted to revise this Terms of Use at any time as it sees fit, and by using Site you are expected to review this Terms of Use on a regular basis. 
<br></br><br></br>
<b>ASSIGNMENT </b> 
<br></br><br></br>
BDP is allowed to assign, transfer, and subcontract its rights and/or obligations under this Terms of Use without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under this Terms of Use. 
<br></br><br></br>
<b>EENTIRE AGREEMENT  </b>
<br></br><br></br>
This Terms of Use constitutes the entire agreement between BDP and you in relation to your use of Site, and supersede all prior agreements and understandings. 
<br></br><br></br>  
  
  </div>
);
}
}
export default TermsOfUse;
