import React from "react";
import bwlogo from '../assets/bwlogo.svg';
import insta from "../assets/instagram.svg";
import facebook from "../assets/facebook.svg";
import telephone from "../assets/telephone.svg";
import email from "../assets/email.svg";
import editButton from "../assets/Edit-Button.png";
import loginSecurity from "../assets/LoginAndSecurity.png";
import commPreferences from "../assets/CommPreferences.png";
import shippingAddresses from "../assets/ShippingAddresses.png";
import orderHistory from "../assets/OrderHistory.png";
import taxExempt from "../assets/TaxExempt.png";
import {Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";

class MyProfile extends React.Component {
    state = {
      userProfile: {  ID_Token: '',
                     FirstName: '', 
                     LastName: '',
                     AddressOne: '',
                     AddressTwo: '', 
                     City: '',
                     State: '', 
                     Zip: '',
                     EmailAddress: ''
                     },
      ID_Token: '',
    FirstName: '', 
    LastName: '',
    AddressOne: '',
    AddressTwo: '', 
    City: '',
    State: '', 
    Zip: '',
    EmailAddress: ''
    }
  
  
  constructor(props) {
    super(props);
  };  


    componentWillMount() {
      
  
      let bdUserProfile = [];

      if(localStorage.getItem('bdUserProfile')){
        bdUserProfile = JSON.parse(localStorage.getItem('bdUserProfile'));
        
    }
    let profileID = '';
    profileID = bdUserProfile[0].userID;
   
    profileID = profileID.replace('auth0|', '');

    console.log("bdUserProfile")
    console.log(profileID)
  
      fetch(`https://services.hatterasmarket.com/api/useraccess/${profileID}`)  
      .then(res => res.json())
      .then((data) => {
        this.setState({ userProfile: data })
                console.log("userProfile")
              console.log(this.state.userProfile)
      })
      .catch(console.log)
      
      this.setState({ ID_Token: this.state.userProfile.ID_Token})
      this.setState({ FirstName: this.state.userProfile.FirstName})
      this.setState({ LastName: this.state.userProfile.LastName})
      this.setState({ AddressOne: this.state.userProfile.AddressOne})
      this.setState({ AddressTwo:  this.state.userProfile.AddressTwo})
      this.setState({ City: this.state.userProfile.City})
      this.setState({ State: this.state.userProfile.State})
      this.setState({ Zip: this.state.userProfile.Zip})
      this.setState({ EmailAddress: this.state.userProfile.EmailAddress})
     
     
  
      
    }
      
   

    

    render() {
    return (
            <div>
                 <span className="profile-page-header">Your Account</span>
                <br/><br/><br/>
                <Container className="border border-primary rounded">
                <Row className="spacer-text">
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                  </Row>
                  <Row>
                    <Col className="profileHeader">FULL NAME<br/></Col>
                    <Col className="profileHeader">ADDRESS<br/><br/></Col>
                    <Col>
                    </Col>
                    <Col>
                    <Link to="editprofile"><img src={editButton}></img></Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="profileText">
                    {this.state.userProfile.firstName + ' ' + this.state.userProfile.lastName }
                    </Col>  
                    <Col className="profileText">
                   {this.state.userProfile.addressOne}<br/>
                   {this.state.userProfile.addressTwo}<br/>
                   {this.state.userProfile.city + ' ' +  this.state.userProfile.state + ' ' + this.state.userProfile.zip}
                  
                    </Col>
                    <Col>
                    </Col>
                    <Col>
                    </Col>
                  </Row>
                  <Row className="spacer-text">
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                  </Row>
                     
                  <Row>
                    <Col className="profileHeader">
                       EMAIL<br/><br/>
                    </Col>
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                  </Row>
                  <Row>
                    <Col className="profileText">
                    {this.state.userProfile.emailAddress} 
                    </Col>
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                  </Row>
                 <Row className="spacer-text">
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                  </Row>
                  {/* <Row>
                    <Col className="profileHeader">
                       PRIMARY PHONE<br/><br/>
                    </Col>
                    <Col className="profileHeader">DEFAULT CURRENCY</Col>
                    <Col></Col>
                    <Col></Col>
                  </Row>
                 <Row>
                  <Col className="profileText">
                       919-609-0722
                    </Col>
                    <Col className="profileText">USD</Col>
                    <Col></Col>
                    <Col></Col>
                  </Row>
                  <Row className="spacer-text">
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                  </Row> */}
                 
                  </Container>
                  <Container>
                  <Row className="spacer-text">
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                  </Row>
                  <Row>
                    <Col><img src={loginSecurity} height="210" width="320"></img></Col>
                    <Link to="/commpreferences">
                    <Col><img src={commPreferences} height="210" width="320"></img></Col></Link>
                    <Link to="/shippingaddresses">
                    <Col><img src={shippingAddresses} height="210" width="320"></img></Col></Link>
                    <Col></Col>
                  </Row>
                  <Row className="spacer-text">
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                  </Row>
                  <Row>
                    <Col><img src={orderHistory} height="210" width="320"></img></Col>
                    <Col><img src={taxExempt} height="210" width="320"></img></Col>
                    <Col></Col>
                    <Col></Col>
                  </Row>
                  <Row className="spacer-text">
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                  </Row>
                  </Container>

                
            </div>

)
}
}



export default MyProfile;
