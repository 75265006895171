import React from "react";
import bwlogo from '../assets/bwlogo.svg';
import insta from "../assets/instagram.svg";
import facebook from "../assets/facebook.svg";
import telephone from "../assets/telephone.svg";
import email from "../assets/email.svg";
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import {Redirect} from 'react-router-dom';
import { Container, Row, Col } from "reactstrap";
import { Route , withRouter} from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import {Link } from "react-router-dom";
import '../App.css';


function wait(ms)
{
var d = new Date();
var d2 = null;
do { d2 = new Date(); }
while(d2-d < ms);
}

class EditProfile extends React.Component {
  
  
  constructor(props) {
    super(props);
    this.state = {
      email: 'done',
      userProfile: {  ID_Token: '',
                      FirstName: '', 
                      LastName: '',
                      AddressOne: '',
                      AddressTwo: '', 
                      City: '',
                      State: '', 
                      Zip: '',
                      EmailAddress: ''},
     ID_Token: '',
     FirstName: '', 
     LastName: '',
     AddressOne: '',
     AddressTwo: '', 
     City: '',
     State: '', 
     Zip: '',
     EmailAddress: ''
             
     }
    
    

    
    this.onInputchange = this.onInputchange.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
    };


    componentDidMount() {
      
  
      let UserProfile = localStorage.getItem("bdUserProfile");

      let bdUserProfile = [];

      if(localStorage.getItem('bdUserProfile')){
        bdUserProfile = JSON.parse(localStorage.getItem('bdUserProfile'));
        
    }
    let profileID = '';
    profileID = bdUserProfile[0].userID;
   
    profileID = profileID.replace('auth0|', '');

    console.log("bdUserProfile")
    console.log(profileID)
  
          fetch(`https://services.hatterasmarket.com/api/useraccess/${profileID}`)  
          .then(res => res.json())
          .then((data) => {
           
                 
                  this.setState(
                    {
                        ID_Token: data.iD_Token,
                        FirstName: data.firstName,
                        LastName: data.lastName,
                        AddressOne: data.addressOne,
                        AddressTwo:  data.addressTwo,
                        City: data.city,
                        State: data.state,
                        Zip: data.zip,
                        EmailAddress: data.emailAddress
                    },
                    function() { console.log("setState completed", this.state) }
                   )
            
               
                  console.log("email");
                  console.log(this.state.EmailAddress)
          })
          .catch(console.log)
          
                   
         
        
        }


        onInputchange(event) {

            console.log(event.target.name)
                console.log(event.target.value)
            this.setState({
              [event.target.name]: event.target.value
            });
          }


    onSubmitForm(e) {
        e.preventDefault();
      
   
    alert('Your profile has been updata!')
    console.log('id token')
    console.log(this.state.ID_Token)
    
    console.log("stringify")
   
    const data = new FormData();
    data.append("ID_Token", this.state.ID_Token);
    data.append("FirstName", this.state.FirstName);
    data.append("LastName", this.state.LastName);
    data.append("AddressOne", this.state.AddressOne);
    data.append("AddressTwo", this.state.AddressTwo);
    data.append("City", this.state.City);
    data.append("State", this.state.State);
    data.append("Zip", this.state.Zip);
    data.append("EmailAddress", this.state.EmailAddress);

    console.log(data);
    fetch(`https://services.hatterasmarket.com/api/useraccess/`, {
      method: "post",
      crossDomain:true,
     mode: "cors", // or w: "cors"ithout this line
    redirect: 'follow',
    contentType: "application/json; charset=utf-8",
    headers: {
       
        
    },
    body: data
   
    }).then((res) => res.text())
    .then((data) =>  console.log(data))
    .catch((err)=>console.log(err));

    
    this.props.history.push('/myprofile');

      }

    render() {
    
      
      
    return (
    <div>

    <span className="profile-page-header">Edit Profile</span><br/><br/>

    <Form className="profile-text" onSubmit={(e) => this.onSubmitForm(e)}>
    <Input type="hidden" name="ID_Token" id="ID_token" value={this.state.ID_Token} onChange={this.onInputchange}/>
    <Input type="hidden" name="EmailAddress" id="EmailAddress" value={this.state.EmailAddress} onChange={this.onInputchange}/>
    <Row form>
        <Col md={6}>
          <FormGroup>
            <Label for="firstName" className="profile-text">First Name</Label>
            <Input type="text" name="FirstName" id="FirstName" value={this.state.FirstName} onChange={this.onInputchange} />
          </FormGroup>
        </Col>
     
        <Col md={6}>
          <FormGroup>
            <Label for="lastName" className="profile-text">Last Name</Label>
            <Input type="text" name="LastName" id="LastName" value={this.state.LastName} onChange={this.onInputchange}/>
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <Label for="exampleAddress" className="profile-text">Address</Label>
        <Input type="text" name="AddressOne" id="AddressOne" value={this.state.AddressOne } onChange={this.onInputchange}/>
      </FormGroup>
      <FormGroup>
        <Label for="exampleAddress2" className="profile-text">Address 2</Label>
        <Input type="text" name="AddressTwo" id="AddressTwo" value={this.state.AddressTwo } onChange={this.onInputchange}/>
      </FormGroup>
      <Row form>
        <Col md={6}>
          <FormGroup>
            <Label for="exampleCity" className="profile-text">City</Label>
            <Input type="text" name="City" value= {this.state.City} id="City" onChange={this.onInputchange}/>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="exampleState" className="profile-text">State</Label>
            <Input type="text" name="State" value={this.state.State } id="State" onChange={this.onInputchange}/>
          </FormGroup>
        </Col>
        <Col md={2}>
          <FormGroup>
            <Label for="exampleZip" className="profile-text">Zip</Label>
            <Input type="text" name="Zip" value={this.state.Zip } id="Zip" onChange={this.onInputchange}/>
          </FormGroup>  
        </Col>
      </Row>
      <Button>Save</Button><br/><br/>
    </Form>
            </div>

)
}
}



export default withRouter(EditProfile);
