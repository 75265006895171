import React from 'react';
import '../App.css';
import PropTypes from "prop-types";


class CartModal extends React.Component {
  static propTypes = {
    productImages: PropTypes.object,
    itemInfo: PropTypes.object,
    showCartModal: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node,
    toggleCartModal: PropTypes.func

  };
  constructor(props) {
    super(props);
    
      
    };

    



 

    render() {
      if(!this.props.showCartModal){
          return null;
      }

        // The gray background
    

    // The modal "window"
    const modalStyle = {
      backgroundColor: '#fff',
      borderRadius: 5,
      maxWidth: 500,
      minHeight: 700,
      margin: '0 auto',
      padding: 30
    };

    const showHideClassName = this.props.showCartModal ? "modal display-block" : "modal display-none";

    return (

      <div className={showHideClassName}>
      <section className="modal-main">
        {this.props.children}
        
      </section>
    </div>

      

    )
  }
}


export default CartModal;


