import React from 'react';
import '../App.css';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import MaterialTable from 'material-table'
import ItemStars from '../assets/productStars.jpeg';
import ProductMessage from '../assets/messageProduct.jpeg';
import { yellow } from '@material-ui/core/colors';
import EditIcon from '../assets/edit-icon.jpeg';
import CopyIcon from '../assets/copy-icon.jpeg';
import DeleteIcon from '../assets/delete-icon.jpeg'; 
import MSRP from '../assets/msrp.jpeg';
import InHandsDate from '../assets/in-hands-date.jpeg';
import { format2decimal } from "../helpers";
import { formatPrice } from "../helpers";
import {Link } from "react-router-dom";
import deleteIcon from '../assets/trashdelete.png'


class CartItemTemplate extends React.Component {
    state = {
      CartProductXrefID: 1, 
     CartID: 1, 
     ProductID: 1, 
     ProductQuantity: 0, 
     ProductPrice: 0, 
     ProductPartDetailID: 0, 
     ProductCost: 0, 
     ProductName: '', 
     ProductDesc: '', 
     ProductSKU: '', 
     ImageURL: ''
    }
  
    static propTypes = {
    productImages: PropTypes.object,
    itemInfo: PropTypes.object,
    showCartModal: PropTypes.bool,
    onClose: PropTypes.func,
    children: PropTypes.node,
    toggleCartModal: PropTypes.func,
    CartItemInfo:PropTypes.object,
    deleteCartItem:PropTypes.func,
    CartProductXrefID: PropTypes.number

  };
  constructor(props) {
    super(props);
    
      
    };

    

    componentWillMount(){
        this.setState({ CartProductXrefID: this.props.CartProductXrefID })
        this.setState({ CartID: this.props.CartID })
        this.setState({ ProductID: this.props.ProductID })
        this.setState({ ProductQuantity: this.props.ProductQuantity })
        this.setState({ ProductPrice: this.props.ProductPrice })
        this.setState({ ProductPartDetailID: this.props.ProductPartDetailID })
        this.setState({ ProductCost: this.props.ProductCost })
        this.setState({ ProductName: this.props.ProductName })
        this.setState({ ProductDesc: this.props.ProductDesc })
        this.setState({ ProductSKU: this.props.ProductSKU })
        this.setState({ ImageURL: this.props.ImageURL })

        console.log(this.props.ProductPrice);
        console.log(this.props.ProductSKU);
        console.log(this.props.ProductPrice);
        console.log(this.props.UnitQuantity);


    }

 

    render() {
   
        
      
    return (

            <div id="CartItemTemplate">
          
             <table width="100%">
               <tbody>
                 <tr>
                   <td width="25"></td>
                   <td width="165" height="165"><img src={this.props.ImageURL} className="cartImage"></img></td>
                   <td width="17"></td>
                   <td width="465">
                     <table>
                       <tbody>
                         <tr>
                           <td colSpan="2" className="cart-title">{this.props.ProductName}<br/>
                                <span className="sku-name">SKU#:  {this.props.ProductSKU } </span>
                           </td>
                         </tr>
                         <tr>
                           <td colSpan="2" className="sku-name">by Lifewave Solutions</td>
                           
                         </tr>
                         <tr>
                           <td>
                               
                           </td>
                           <td rowSpan="2">
                            
                           </td>
                         </tr>
                         <tr>
                           <td colSpan="2" className="sku-name">
                               <table width="350">
                                   <tbody>
                                       {/* <tr>
                                       <td width="75" className="sku-name" align="left" valign="top">{this.props.PriceTypeDescription}</td>
                                       <td width="275" align="left" valign="top" className="container-qty"></td>
                                       </tr> */}
                                       <tr>
                                         <td className="sku-name">
                                            <Link className="sku-name" to="/cart/1" onClick={() => this.props.deleteCartItem(this.props.CartProductXrefID, this.state.ProductName)}><img  src={deleteIcon}/>Delete</Link>
                                           </td></tr>
                                   </tbody>
                               </table>
                              
                           </td>
                         </tr>
                       </tbody>
                     </table>
                   </td>
                   <td width="225" valign="top" align="right">
                   <br></br>
                   <table  width="225" >
          
                           <tbody>
                             <tr>
                               <td colSpan="2">
                                  <table className="blue-box-3">
                                    <tr>
                                      <td>
                                         &nbsp;&nbsp;{this.props.PriceTypeDescription}
                                     </td>
                                     </tr>
                                     </table>
                               </td>
                             </tr>
                            <tr>
                                                              <td>
                                                            <span className="prod-totals-small"><b>Unit Price:</b> </span>
                                                            </td>
                                                            <td align="right"><span className="prod-totals-small" >{ formatPrice(this.props.ProductPrice/this.props.UnitQuantity) }
                                                            </span>
                                                             </td>
                                                             </tr>   
                                                            
                                                             <tr>
                                                              <td>
                                                            <span className="prod-totals-small">
                                                                <b>Total Quantity:</b>  </span></td>
                                                                <td align="right"><span className="prod-totals-small">{this.props.UnitQuantity}
                                                                </span>
                                                             </td>
                                                             </tr> 
                                                             <tr>
                                                              <td>
                                                            <span className="prod-totals-small">
                                                                <b>Total Price:</b> </span></td>
                                                                <td align="right"><span className="prod-totals-small" >{formatPrice(this.props.ProductPrice)}
                                                                
                                                                </span>
                                                             </td>
                                                             </tr>
                                                             </tbody>
                                                             </table> 
                       {/* <table width="192" className="xsmall-table">
                           <tbody>
                            <tr>
                                <td className="qty-box">Unit Quantity</td>
                                <td className="qty-box">Unit Price</td>
                            </tr>
                            <tr>
                                <td>{this.props.UnitQuantity}</td>
                                <td>{formatPrice(format2decimal(this.props.ProductPrice)/format2decimal(this.props.UnitQuantity) )}</td>
                            </tr>
                            </tbody>
                       </table> */}

                       
                   </td>
                   
                 </tr>



                 <tr>
                     <td colSpan="10" className="cartHeader"></td>
                 </tr>
               </tbody>
             </table>
            
             </div>
            
                  

      

    )
  }
}


export default CartItemTemplate;


