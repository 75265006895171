import React, { Component } from "react";
import bwlogo from '../assets/bwlogo.svg';
import { Row, Col } from "reactstrap";
import insta from "../assets/instagram.svg";
import facebook from "../assets/facebook.svg";
import telephone from "../assets/telephone.svg";
import email from "../assets/email.svg";




class OrderPolicy extends Component {

   
  render() {
    return (
  <div className="p-3 text-left">
  <br/><br/>
    <h2>Order Policy</h2><br/><br/>
  </div>
);
}
}
export default OrderPolicy;
