import React, { Component } from "react";
import bwlogo from '../assets/bwlogo.svg';
import { Row, Col } from "reactstrap";
import insta from "../assets/instagram.svg";
import facebook from "../assets/facebook.svg";
import telephone from "../assets/telephone.svg";
import email from "../assets/email.svg";




class Privacy extends Component {

   
  render() {
    return (
  <div className="p-3 text-left">
    
    <b>PRIVACY POLICY</b> <br></br> <br></br> 

Last Updated: July 1, 2021  <br></br> <br></br> 

Welcome to Blue Pelican Software LLC d/b/a BuyDirectPro (“BDP”), and its website, buydirectpro.com (“Site”). We care about our users and their privacy. This Privacy Policy ("Privacy Policy") describes the information BDP collects through Site and how it is used. <br></br> <br></br>    

This Privacy Policy applies to your, or your company’s use of our Site. This Privacy Policy also applies to the information you provide to us after being presented with or directed to this Privacy Policy. This could be, for example, when you share your information with our service representatives, or send us a letter, email, text or chat.    <br></br> <br></br> 

Before using Site, please review this Privacy Policy carefully.  <br></br> <br></br> 

<b>INFORMATION WE COLLECT AND HOW WE USE YOUR INFORMATION  </b>  <br></br><br></br>

BDP, through Site, collects the following types of information:  <br></br>

(1) Information you give to us;  <br></br>

(2) Information we automatically collect from your use of the Site; and  <br></br>

(3) Information we collect from third-parties.  <br></br>

The information collected is used as defined below.  <br></br><br></br>

<b><i>1)	Information you give to us</i></b><br></br><br></br>

<li>Your name and contact details (such as your email address, mailing address, phone 	number, and password)</li>  

<li>Additional profile information (such as preferred language, and time zone)  </li>

<li>Your communications and contact history with us (such as service call recordings, 	 emails, chat and instant messages, or other communications)  </li>

<li>Other information (such as product reviews, forum posts, survey responses, participation 	in promotions, and other user-generated content)</li>  <br></br><br></br>

BDP uses your name and contact details to create your account. This information is necessary for the adequate performance of certain services and offerings on the Site, and to allow you to place an order on the Site.  <br></br><br></br>

BDP uses your name and contact details to help fulfill any order between you and Product Supplier (as defined in the Terms of Use). All financial transactions will be processed by a third-party payment service provider which is not affiliated with BDP or Site. <br></br><br></br>

BDP will use your name and contact details to deliver important account information to you, including, but not limited to: information regarding any orders placed, changes to account settings, and notices about the Site, or the terms and policies that apply to it, including, but not limited to, modifications to the Terms of Use, this Privacy Policy, or other BDP documents. <br></br><br></br> 

BDP will use your communications and contact history with us, along with other profile and Site use information will be used to operate, protect, enhance, and optimize your experience on the Site.  <br></br><br></br>

All the information BDP collects through Site will allow us to improve our services and offerings, perform other internal analytics and performance monitoring, as well as help us deliver and personalize our communications with you, including administering incentives, rewards, surveys, or other promotional activities.  
<br></br><br></br>


<b><i>2)	Automatically collected information  </i></b><br></br><br></br>
<li>Your purchase history on Site, as well as items placed in your cart or saved </li>

<li>Log data and device information (such as details about how you've used the Site, IP address, access dates and times, hardware and software information, device information, device event information, unique identifiers, crash data, cookie data, location data and page/product views or clicks)  </li>

<li>Geo-location information, including IP address, browser information or mobile GPS information</li> 

<li>Cookies and similar technologies like web beacons, pixels, and mobile identifiers.  To learn more about these technologies and how you can control them, see Our Cookie Policy below. </li>
<br></br><br></br>
BDP will use your log data and device information to secure, operate, protect, improve and optimize Site and your experience with Site. BDP uses automatically collected information to allow us to comply with applicable laws and regulations. BDP will use the automatically collected information to perform internal analytics, detect and prevent fraud and improve the Site’s services and offerings. BDP uses cookies and similar technologies to help provide the most relevant advertising to you.  <br></br><br></br>
  
<b><i>3)	Third-party account information (Google, Facebook, Twitter, Instagram, and others) 	that you link with BDP through Site </i></b><br></br><br></br>

<li>Your name and contact details (such as your email address, mailing address, phone number and password)  </li>

<li>Social media usernames and/or handles </li><br></br><br></br>

Demographic data, fraud detection information and information about you and your activities, experiences and interactions on and off the Site<br></br><br></br>

BDP will use information from third-party services if you register an account with BDP through Site using another third-party service including Google’s one-tap login service. BDP does not require you to create an account through any third-party service. It is your option to create your account by connecting through another service, but BDP will collect public information from that connected account to help complete your profile and improve your experience. BDP may use the collected third-party information to deliver and personalize our communications with you, or to administer referral programs, rewards, surveys, sweepstakes, contests or other promotional activities. This information will also aid in BDP in fraud prevention and risk assessment activities.  <br></br><br></br>

BDP may obtain your information from a friend or other contact who wants to invite you to use the Site. We will use your information to facilitate your invitation and administer our referral program. <br></br><br></br> 


<b>WHEN BDP SHARES YOUR INFORMATION</b><br></br><br></br>

BDP will share your information with the following categories of recipients as an essential part of being able to provide Site and its related services and offerings, including customer service, to you:  <br></br><br></br>

<li>BDP subsidiaries and affiliates. Companies and individuals that help us deliver our products to you, including, but not limited to Product Suppliers, payment service providers, logistics professionals, and delivery and freight companies.  </li>

<li>Professional service providers who help us run our business, such as marketing companies, software vendors, consultants, and advertising partners.  </li>

<li>Companies approved by you, such as social media sites (if you choose to link your accounts to us) and payment processors or financing providers and card issuing banks if you choose to use their services or offerings.  </li>

<li>Government and law enforcement agencies, to (i) comply with a legal process, subpoena, order, or other legal or regulatory requirement; (ii) enforce our Terms of Use or other policies; or (iii) pursue available legal remedies or defend legal claims.  </li>
<br></br><br></br>
BDP only shares your information with third-parties as described in this Privacy Policy or as disclosed at the time we collect your information. We may anonymize your information and share such anonymized information with third-parties for any purpose permitted by applicable laws.  
<br></br><br></br>

<b>BDP COOKIE POLICY </b><br></br><br></br>
Cookies are small text files that are stored by your Internet browser on your computer. BDP uses cookies when you visit Site to personalize your shopping experience by analyzing traffic, and optimizing performance and content. While our cookies may change from time to time, we generally use the following types of cookies:  <br></br><br></br>

<li>Functionality cookies allow you navigate Site and its features.  </li>

<li>Analytics cookies allow BDP to measure, analyze and understand how our customers use and interact with Site so we can find ways to improve Site’s functionality and your experience while using Site. As an example, BDP uses Google Analytics to help us evaluate your use of Site, to compile reports on website activity, and to provide other services related to website activity and internet usage. To learn how Google Analytics collects and processes data, please visit: “How Google uses information from sites or apps that use our services” located at www.google.com/policies/privacy/partners.  </li>

<li>Customer preference cookies allows BDP, through Site, to remember your preferences, like your language or location.  </li>

<li>Targeting or advertising cookies, allows BDP to deliver digital ads relevant to you. These types of cookies also limit the number of times that you see an ad and help us measure the effectiveness of our marketing campaigns.  To disable interest-based ads, please see Targeted Marketing and Behavioral Advertising below.  </li>

<li>You can prevent the use of cookies by changing the settings in your web browser so that (i) it does not accept new cookies, (ii) it informs you about new cookies, or (iii) it deletes all already received cookies. By deleting or disabling future cookies, your user experience may be affected and you might not be able to take advantage of certain functions of our Site, which may include storing items in your Cart between visits, customizing your website experience, and making marketing messages more relevant.  </li>

<li>We also use web beacons to help us identify your computer and evaluate user behavior, such as reactions to promotions. Web beacons are small GIF files that can be embedded in other graphics, emails, or similar. To prevent web beacons on our site, you can use tools such as AdBlock Plus. To prevent web beacons in emails, please set your mail program so that no HTML is displayed in messages.</li>

 <br></br><br></br>
 <b>TARGETED MARKETING AND BEHAVIORAL ADVERTISING    </b> <br></br><br></br>
 BDP may directly send you marketing communications and materials by email and other channels to keep you informed of new products, promotions, and to provide other information that may be important for you. You can stop receiving targeted marketing messages from BDP by clicking any "unsubscribe" link in any marketing message you receive from BDP. Please allow up to 48 hours to process your request and update our systems. Even if you opt out of BDP marketing messages, we will continue to send you administrative and service communications, like order updates and other important or transactional information.
 <br></br><br></br>
 <b>BLOGS, REVIEWS AND FORUMS</b><br></br><br></br>
 BDP, through Site, may offer publicly-accessible blogs, reviews or forums. Any information you provide in these areas may be read, collected and used by others who access them. To request removal of your information from blogs, customer reviews or forums, please contact us here. Please note that in some cases we may be unable to remove your information.  <br></br><br></br>
<b>OUR SECURITY MEASURES    </b><br></br><br></br>
At BDP, our users’ security is of the utmost importance. BDP uses appropriate technical and organizational safeguards to protect your information from unauthorized use, disclosure, and loss. Encryption technology, including current industry-standard encryption protocols, is used to protect personal information in certain areas of our websites during transmission across the Internet. If you have questions about the security of your information, please contact us at david.sturdivant@buydirectpro.com.  
<br></br><br></br>
While BDP has implemented various security safeguards for you while using Site, you still need to take reasonable measures to protect your password and your computer to prevent unauthorized access to your account. Signing out of your account, and closing your browser window, especially if using a public or shared computer, will help keep your account and information private.   
<br></br><br></br>
<b>HOW LONG WE KEEP YOUR INFORMATION  </b>
<br></br><br></br>
BDP will keep your information for as long as you have an account or as long as we need it to fulfill the purpose for which it was collected or disclosed to you at the time of collection. We may also keep some of your information as required to meet legal or regulatory requirements, resolve disputes, prevent fraud or abuse, enforce our Terms of Use this Privacy Policy, or for other permissible purposes.  
<br></br><br></br>
BDP will either delete your information from our systems or anonymize it so that it no longer identifies you once we no longer have need for the information.  
<br></br><br></br>
<b>CHILDREN'S PRIVACY  </b><br></br><br></br>
The Site and its services are not directed to children, and we do not knowingly collect information from children under 13. If you are a parent or guardian and believe that we have information about your child, please contact us at@buydirectpro.com. To learn more about how to protect your child online, visit the FTC’s www.OnGuardOnline.gov.  
<br></br><br></br>
<b>INFORMATION FOR VISITORS FROM CALIFORNIA, VIRGINIA AND OUTSIDE OF THE UNITED STATES    </b>
<br></br><br></br>
Privacy Rights for California and Virginia Residents  
<br></br><br></br>
This privacy policy describes the categories of personal information we collect from you, the methods and business purposes of collecting such personal information, the categories of third-parties with whom we may share it, and the rights you have regarding the personal information. California and Virginia residents have the right to request that BDP disclose what personal information it collects, uses, discloses or sells. BDP does not sell your personal information to third-parties without your prior consent. California and Virginia residents also have the right to request the deletion of their personal information collected or maintained by BDP. If you wish to make a request for information or to delete your account, or for any questions about our privacy practices and compliance with California and Virginia law, please contact the BDP customer service team via email at cs@buydirectpro.com. You can also designate an authorized agent to make a request on your behalf. Prior to responding to your request, we will verify your identity by matching any requested identifying information you provide against the information we have about you. We will not subject you to discriminatory treatment as a result of your choice to exercise your privacy rights.  
<br></br><br></br>
<b>RIGHT TO REMOVAL OF POSTED INFORMATION--CALIFORNIA AND VIRGINIA MINORS   </b>
<br></br><br></br>
If you are under 18 years of age, reside in California or Virginia, and have a registered account with BDP, you have the right to request removal of unwanted information that you publicly post on Site. To request removal of such information, you can contact BDP as provided below. Upon receiving such a request, BDP will make sure that the information is not publicly available on Site, but the information may not be completely or comprehensively removed from our systems and databases, or from other third-party sites used to post the information.  
<br></br><br></br>
<b>VISITORS FROM OUTSIDE OF THE UNITED STATES </b>
<br></br><br></br>
BDP hosts Site in the United States. If you are visiting Site from outside the United States, your information may be transferred to, stored and processed in the United States in accordance with this Privacy Policy and applicable US laws. Please note that data protection and other applicable laws of the United States may not be as comprehensive as those laws or regulations in your country or may otherwise differ from the data protection or consumer protection laws in your country. By using Site, you consent to transfer of your information to our facilities as described in this Privacy Policy. 
<br></br><br></br>
<b>CHANGES TO THIS PRIVACY POLICY   </b>
<br></br><br></br>
BDP may amend this Privacy Policy from time to time to reflect new ways that we process your information. When we post modifications to this Privacy Policy, we will revise the "Last Updated" date at the top of this page. The modified Privacy Policy will be effective immediately upon posting on Site. If we make significant changes that affect your information, we will provide you notice of such changes through Site, by email, or some other means of contact. We encourage you to periodically review this page for the latest information on our privacy practices.  
<br></br><br></br>
<b>CONTACT US    </b>
<br></br><br></br>
We greatly value our relationships with our customers, and welcome any questions, comments or concerns about our privacy practices. Please don't hesitate to contact our customer services team via email at  cs@buydirectpro.com. 
<br></br><br></br>
  </div>
);
}
}
export default Privacy;
