import React, { Component } from "react";
import bwlogo from '../assets/bwlogo.svg';
import insta from "../assets/instagram.svg";
import facebook from "../assets/facebook.svg";
import telephone from "../assets/telephone.svg";
import email from "../assets/email.svg";
import "../App.css";
import { Container, Row, Col } from "reactstrap";
import AboutUsHeader from "../assets/AboutUsHeader.png";
import AboutUsSimplicity from '../assets/AboutUsSimplicity.png';
import AboutUsBTO from '../assets/AboutUsBTO.png';
import AboutUsFreeShipping from '../assets/AboutUsFreeShipping.png';
import AboutUsCustomerService from '../assets/AboutUSCustomerService.png';
import NoHassle from "../assets/No Hassle.png";
import AdvancedTechnology from "../assets/Advanced Technology.png";
import WeDeliverValue from "../assets/We Deliver Value.png";
import OnlineMarketPlace from "../assets/Online Marketplace.png";
import AboutUsSlider from "../assets/AboutUsSlider.jpg"



class AboutUs extends Component {

   
  render() {
    return (
<div>
    
    <Container>
      <Row>
        <Col colSpan="3" align="middle">
          <img src={AboutUsSlider} width="100%"/>
        </Col>
      </Row>
      <Row>
        <Col colspan="3" className="spacer-text"> 
        </Col>
      </Row>
      {/* <Row>
        <Col colSpan="3" align="middle" height="300px">	<h2>BuyDirectPro enables professionals to source top-quality,<br/> certified products directly from best-in-class manufacturers.  Our online marketplace features factory-direct pricing, the convenience of ordering online, and automated order fulfillment.  </h2></Col>
        
      </Row> */}
      {/* <Row>
        
        <Col colspan="3" className="spacer-text"> 
        </Col>
      </Row> */}
      
        
        <Row>
        
        <Col colspan="3" className="spacer-text"> 
        </Col>
      </Row>
      <Row>
    <Col border="1"><br/>
    <div className="subTextWhyBuyCenter">
    <img src={OnlineMarketPlace} /><br/><br/><br/>
    <b>Online Marketplace </b><br/><br/>
      </div>
    
      <span className="subTextWhyBuy">We have removed the hassle of the traditional estimating and quoting process.  Easily review and select products with best-in-market pricing that is continuously updated online.</span>
    </Col>
    <Col><br/>
    <div className="subTextWhyBuyCenter">
    <img src={AdvancedTechnology} /><br/><br/><br/>
    <b>Advanced Technology</b><br/><br/>
      </div>
    
    <span className="subTextWhyBuy">Select products, purchase, and submit your order to manufacturers online.  Know when your order goes into production, ships from the factory, and is expected to arrive at your facility or jobsite.  </span>
    </Col>
    
    <Col><br/>
    <div className="subTextWhyBuyCenter">
    <img src={WeDeliverValue} /><br/><br/><br/>
    <b>We Deliver Value</b><br/><br/>
      </div>
    <span className="subTextWhyBuy">Our Customer Service team stands ready to assist you before, during, and after a purchase.  Contact us today and let us use our knowledge and resources to exceed your expectations.</span>
    </Col>
  </Row>
  <Row>
        
        <Col colspan="2" className="spacer-text"> 
        </Col>
      </Row>
          </Container>
</div>
);
}
}
export default AboutUs;
