import React from "react";
import {Table} from "reactstrap";
import { Icon } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import {Link } from "react-router-dom";
import { WatchOutlined } from "@material-ui/icons";

function wait(ms)
{
var d = new Date();
var d2 = null;
do { d2 = new Date(); }
while(d2-d < ms);
}

class ShippingAddress extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
     
      
    
      AddressList: [
        { AddressID: 0, AddressTypeID: 0,  AddressLine1: '1412 Shadowview Ct', AddressLine2: 'Suite 200', AddressCity: 'Raleigh', AddressState: 'NC', AddressZip: '27614', IDToken: '' }],
       
      
    }

    this.deleteAddress = this.deleteAddress.bind(this);
      
    };
   
  
    
  

    componentDidMount() {
      
  
      let bdUserProfile = [];

      if(localStorage.getItem('bdUserProfile')){
        bdUserProfile = JSON.parse(localStorage.getItem('bdUserProfile'));
        
    }
    let profileID = '';
    profileID = bdUserProfile[0].userID;
   
    profileID = profileID.replace('auth0|', '');

    console.log("bdUserProfile")
    console.log(profileID)
    
    wait(500)
      fetch(`https://services.hatterasmarket.com/api/address/${profileID}`)  
      .then(res => res.json())
      .then((data) => {
       
             
              this.setState(
                {
                  AddressList: data
                },
                function() { console.log("setState completed", this.state) }
               )
        
           
      })
      .catch(console.log)
      
               
     
    
    }
    

    renderTableData() {
      
      return this.state.AddressList.map((Address, index) => {
        let linkvariable = "/editshippingaddress/" + Address.addressID;
        
         return (

            <tr key={index}>
               <td>{Address.addressLine1}</td>
               <td>{Address.addressLine2}</td>
               <td>{Address.addressCity}</td>
               <td>{Address.addressState}</td>
               <td>{Address.addressZip}</td>
               <td>
                <Link to={linkvariable}>
                 <Tooltip title="Edit Address" placement="bottom">
                 <EditIcon></EditIcon>
                 </Tooltip>
                 </Link>
                  <Link to="/shippingaddresses" onClick={() => this.deleteAddress(Address.addressID, Address.addressLine1)} >
                 <Tooltip title="Delete Address" placement="bottom"> 
                 <DeleteIcon></DeleteIcon>
                 </Tooltip>
                 </Link>
                 
                 </td> 
            </tr>
         )
      })
   }


   deleteAddress = (AddressID, AddressLine1) => {

    alert("You deleted " + AddressLine1)
    

   
    
    fetch(`https://services.hatterasmarket.com/api/address/${AddressID}`, {
      method: "Delete",
      crossDomain:true,
  mode: "cors", // or without this line
redirect: 'follow',
headers: {
    "Content-type": "application/json",
    "Accept": "application/json",
    "Accept-Charset": "utf-8"
    
},
  }).then((res) => res.text())
  .then((data) =>  console.log(data))
  .catch((err)=> console.log(err));

 this.reloadAddresses();
  

}

reloadAddresses  = () => {
  


  let bdUserProfile = [];

  if(localStorage.getItem('bdUserProfile')){
    bdUserProfile = JSON.parse(localStorage.getItem('bdUserProfile'));
    
}
let profileID = '';
profileID = bdUserProfile[0].userID;

profileID = profileID.replace('auth0|', '');

console.log("bdUserProfile")
console.log(profileID)

wait(500)
  fetch(`https://services.hatterasmarket.com/api/address/${profileID}`)  
  .then(res => res.json())
  .then((data) => {
   
         
          this.setState(
            {
              AddressList: data
            },
            function() { console.log("setState completed", this.state) }
           )
    
       
  })
  .catch(console.log)

  
  
}


    render() {
      
      
    return (
             <div>
                 <span>Shipping Addresses</span>
                 <br/>
                
        
    
        
     
              
    <Table>
      <thead>
        <tr>
          <th>Address One</th>
          <th>Address Two</th>
          <th>City</th>
          <th>State</th>
          <th>Zip</th>
          <th>
            <Link to="/AddShippingAddress">
          <Tooltip title="Add New Address" placement="bottom">
                 <AddLocationIcon></AddLocationIcon>
                 </Tooltip>
                 </Link>
          </th>
        </tr>
      </thead>
      <tbody>
      {this.renderTableData()}
      </tbody>
      </Table>          
    </div>
    )


}
}



export default ShippingAddress;
