import React, { Component } from "react";
import ImageBox from "./ImageBox";
import {
  ReactiveBase,
  DataSearch
} from "@appbaseio/reactivesearch";

import { Row, Col, Container } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import contentData from "../utils/contentData";
import arrow from '../assets/arrow.svg';
import {Redirect} from 'react-router-dom';
import search from "../assets/searchbutton.svg";
import efficiency from "../assets/efficiency.jpg";
import convenience from "../assets/convenience.jpg";
import bestprice from "../assets/best-prices.jpg";
import electricalContractors from "../assets/ElectricalContractors.svg";
import residential from "../assets/residentialandcommercial.svg";
import government from "../assets/governmentagencies.svg";
import residentialcat from "../assets/residential.svg";
import commercial from "../assets/commercial.svg";
import outdoor from "../assets/outdoor.svg";
import indoor from "../assets/indoor.svg";
import energyServices from "../assets/energyservices.svg";
import '../App.css';
import { Route , withRouter} from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import newImage from "../assets/newConstructionPic2.PNG";
import middle from "../assets/middleContent.PNG";
import thirdpiece from "../assets/thirdpiece.PNG";
import AdvancedTechnology from "../assets/gears2.PNG";
import WeDeliverValue from "../assets/people2.PNG";
import OnlineMarketPlace from "../assets/Computer2.PNG";
import AboutUsSlider from "../assets/AboutUsSlider.jpg"

class Content extends Component {

  state = {
    value: ""
  };

  handleSearch = value => {
    this.setState({
      value
    });
  }
  
   goToSearch = event => {
    // 1. Stop the form from submitting
    event.preventDefault();
    // 2. get the text from that input
    const searchTerm = this.state.value;
   
    
    this.props.history.push(`/productkeywordsearch/${searchTerm}`);

  };

   
  render() {
    return (
      <div>
        <Container>
      <Row>
        <Col colspan="3">
      
        <img
                     
                      src={newImage}
                      width="100%"
                      alt="Profile"
                    /><br/><br/>
        
        </Col>
          </Row>
        
     
      
        
        <Row>
        
        <Col colspan="3" className="spacer-text"> 
        </Col>
      </Row>
      <Row>
    <Col width="33%"><br/>
    <div className="subTextWhyBuyCenter">
    <img src={OnlineMarketPlace} /><br/><br/><br/>
    <b>App Integration </b><br/><br/>
      </div>
    
      <span className="subTextWhyBuy">Easily connect from within your estimation software application to BuyDirectPro for current product and pricing information.
</span>
    </Col>
    <Col width="33%"><br/>
    <div className="subTextWhyBuyCenter">
    <img src={AdvancedTechnology} /><br/><br/><br/>
    <b>Data Services Hub</b><br/><br/>
      </div>
    
    <span className="subTextWhyBuy">Data Services Hub acts as a centralized marketplace gateway connecting buyers and sellers with interactive, up-to-date product details.  </span>
    </Col>
    
    <Col width="33%"><br/>
    <div className="subTextWhyBuyCenter">
    <img src={WeDeliverValue} /><br/><br/><br/>
    <b>Supplier Network</b><br/><br/>
      </div>
    <span className="subTextWhyBuy">BuyDirectPro enables suppliers to extend their e-commerce investments to develop additional sales channels and reach new customer markets.</span>
    </Col>
  </Row><br/><br/>
  <Row>
      <Col colspan="3">
      <img
                     
                     src={thirdpiece}
                     alt="Profile"
                     width="100%"

                   /><br/><br/>
      </Col>
  </Row>
  </Container>

      
       
        </div>

      
    );
  }
}

export default withRouter(Content);
