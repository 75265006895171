import React, { Component } from "react";
import bwlogo from '../assets/bwlogo.svg';
import insta from "../assets/instagram.svg";
import facebook from "../assets/facebook.svg";
import telephone from "../assets/telephone.svg";
import email from "../assets/email.svg";
import "../App.css";
import { Container, Row, Col } from "reactstrap";
import AboutUsHeader from "../assets/AboutUsHeader.png";
import AboutUsSimplicity from '../assets/AboutUsSimplicity.png';
import AboutUsBTO from '../assets/AboutUsBTO.png';
import AboutUsFreeShipping from '../assets/AboutUsFreeShipping.png';
import AboutUsCustomerService from '../assets/AboutUSCustomerService.png';
import AdvancedTechnology from "../assets/Advanced Technology.png";
import WeDeliverValue from "../assets/We Deliver Value.png";
import BestPrices from '../assets/Best Prices.png';
import EliminateMarkup from '../assets/Eliminate Markup.png';
import CertifiedSuppliers from '../assets/Certified Suppliers.png';
import NoHassle from '../assets/No Hassle.png';
import ShippingIncluded from '../assets/Shipping Included.png';
import OrderAutomation from '../assets/Order Automation.png';




class SimplifyProcurement extends Component {

   
  render() {
    return (
<div className="text-center">
    
    <Container>
      <Row>
        <Col colSpan="3" align="left">
          <h2>Simplify Your Procurement Process</h2>
        </Col>
      </Row>
      {/* <Row>
        <Col colspan="3" className="spacer-text"> 
        </Col>
      </Row> */}
      {/* <Row>
        <Col className="frontPageHeaders" colSpan="3" align="middle" height="300px"> </Col>
        
      </Row>
      <Row>
        
        <Col colspan="3" className="spacer-text"> 
        </Col>
      </Row>
      
        
        <Row>
        
        <Col colspan="3" className="spacer-text"> 
        </Col>
      </Row>*/}
      <Row> 
    <Col border="1"><br/>
    <img src={NoHassle}/><br/><br/><br/>
      <span className="subText2"><b>No Hassle</b><br/><br/> 

Say goodbye telephone tag and endless emails of the traditional quoting process.  Easily review pricing and product information online – saving time and money.  </span>
    </Col>
    <Col><br/>
    <img src={ShippingIncluded}/><br/><br/>
    <span className="subText2"><br/><b>Shipping Included</b><br/><br/>From manufacturers around the world, online pricing includes shipping, handling, and tariffs to your destination of choice in the United States (lower 48) and Canada.   </span>
    </Col>
    
    <Col><br/><br/>
    <img src={OrderAutomation} /><br/><br/><br/>
    <span className="subText2"><b>Order Automation</b><br/><br/>Easily submit orders and payment online.  Notifications provide real-time visibility to when your order goes into production, ships from the factory, and when you can expect delivery to your facility or jobsite.  </span>
    </Col>
  </Row>
  <Row>
        
        <Col colspan="2" className="spacer-text"> 
        </Col>
      </Row>
          </Container>
</div>
);
}
}
export default SimplifyProcurement;