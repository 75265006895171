import React, { Component } from "react";
import bwlogo from '../assets/bwlogo.svg';
import { Row, Col } from "reactstrap";
import insta from "../assets/instagram.svg";
import facebook from "../assets/facebook.svg";
import telephone from "../assets/telephone.svg";
import email from "../assets/email.svg";




class ShippingAndDeliveryPolicy extends Component {

   
  render() {
    return (
  <div className="p-3 text-left"><br/><br/>
    <h2>Shipping and Delivery Policy</h2><br/><br/>
    <h3>Overview</h3>
BuyDirectPro's supplier partners and their factory facilities are located around the world, producing products on a made-to-order basis.  The BuyDirectPro marketplace provides the logistics to determine the best mode of transportation for orders in the most cost-effective, efficient method possible while keeping customers informed every step of the way.  We service customer locations within the US (contiguous lower 48 states) and Canada.  
<br/><br/>
<h3>Shipping Process</h3>
Containers are packed at the factory and transported in the country origin to ports and loaded onto cargo ships.  Cargo ships travel global ocean routes to a US or Canadian destination port best suited for customer's final delivery location.  From the destination port, goods are transported by rail and truck without unloading or reloading cargo.  Shipping times may vary based on final destination location, extreme weather, and other related factors.
<br/><br/>
<h3>
Shipping Methods</h3>
Containers are shipped via intermodal freight to transport large-quantity shipments by land and sea using a combination of large ships, rail, and trucks.  To move large volumes of cargo in the most efficient way possible, we transport products packed on pallets using Full Container Load (FCL) shipping in 20-foot and 40-foot containers.  All shipments are shipped FOB Destination whereby the supplier partner bears the cost of freight and remains responsible for the delivery of goods to the customer's final destination.
<br/><br/>
<h3>
Order Status Notifications and Tracking</h3>
After submitting an order online, BuyDirectPro provides an initial order confirmation, as well as periodic order status notifications during manufacturing and shipping tracking information.  Trucking carriers will contact the customer to schedule delivery and confirm details as the date of delivery approaches.
<br/><br/>
<h3>
Container Delivery and Unloading</h3>

Please note the process of unloading cargo and moving to a desired location is the responsibility of the customer.  Containers are delivered curbside via semi-trailer truck; we recommend establishing a dedicated area free from unnecessary vehicles and people for unloading.  Customers should plan on being prepared to unload cargo with labor and equipment such as a forklift or pallet jack. 
<br/><br/>
Containers are delivered locked with a seal that is compliant with established container sealing requirements.  It is imperative that the seal be present when the container is delivered. The main function of the container seal is to ensure that no one has access to the cargo from the time it has been sealed at point of origin until the time it has been received.  Seals should only be removed by an authorized customer representative.  Seals are made to be tough to break, so the best way to remove them is with a bolt cutter.
<br/><br/>
During transport cargo may have shifted and changed positions, so open the doors with caution.   Customers are allotted 2 business days to unload cargo and contact the carrier to return and retrieve emptied container.  If customer fails to complete unloading within 2 business days, carrier fees may apply.  Each order will be accompanied by complete instructions for the customer regarding container delivery, unlocking, unloading and return to carrier.
  </div>
);
}
}
export default ShippingAndDeliveryPolicy;
