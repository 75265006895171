import React, { useState } from "react";
import { Component } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from '../assets/logo.svg';
import signIn from '../assets/signIn.svg';
import '../App.css';
import { Route,  withRouter } from 'react-router-dom';
import queryString from 'query-string';
import {Link } from "react-router-dom";



import { formatPrice } from "../helpers";
import {
  ReactiveBase,
  DataSearch,
  MultiList,
  SelectedFilters,
  ResultCard,
  ReactiveList
} from "@appbaseio/reactivesearch";
//import "./App.css";
import "../App.css";





    class ProductKeywordSearch extends Component {
      constructor(props) {
        super(props);
      this.state = {
        categoryList: ['', ''],
        categoriesToHand: [{category : ''}],
        keywordList: [{keyword : ''}],
        categoryValue: '' ,
        value: ''
      }
    }

     
      
    
    handleSearch = value => {
        this.setState({
          value
        });
      };
      
     

     componentDidMount()
     {
      const categories = queryString.parse(this.props.location.search);
      this.setState({categoryList: categories});

      this.setState({value: this.props.match.params.keyword});
      
    const categoryString = categories.category;
    this.setState({ 
      categoriesToHand: this.state.categoriesToHand.concat([categoryString])
    })
    
    console.log('category')
    console.log(categories)
    console.log('categorystring')
    //
    console.log(categoryString)
     }

      
    

  
    

   


      
      render() {
        return (
    <div className="text-center my-5">
       <table>
      <tr>
      <td width="25px"></td>
      <td>
      <span align="left" className="header-span"><h2>Products</h2></span>
      </td>
      </tr>
      </table>
    <ReactiveBase
    app="bdpproducts"
    url="https://search.buydirectpro.com"
    //url="http://10.0.3.54:2903"
  >


<div className={"display"}>
          <div className={"leftSidebar"}>
            
            
          
            <MultiList
              componentId="authorFilter"
              dataField="productCategories.productCategoryName.raw"
              nestedField="productCategories"
              title="Product Categories"
              showSearch={false}
              //size={1000}
              showCheckbox={true}
              className="authors"

              innerClass={{
                list: "author-list",

              }}
              react={{
                and: [
                  "mainSearch",
                   "sizeFilter",
                   "wattageFilter",
                   "lumensFilter",
                  "dimmableFilter", 
                  "cctFilter"
                ]
              }}
              //placeholder="Filter by Product Category"
              filterLabel="Categories"
              //URLParams={false}
             
              
            />
            <hr></hr>
             <MultiList
              componentId="sizeFilter"
              dataField="productSize.size.raw"
              nestedField="productSize"
              title="Product Size"
              size={1000}
              showCheckbox={true}
              showSearch={false}
              className="authors"
              innerClass={{
                list: "author-list",

              }}
              react={{
                and: [
                  "mainSearch",
                  "authorsFilter",
                  "lumensFilter",
                  "wattageFilter",
                  "dimmableFilter", 
                  "cctFilter"
                ]
              }}
              placeholder="Filter by Product Size"
              filterLabel="Size"
              
            />

<hr></hr>
            <MultiList
              componentId="lumensFilter"
              dataField="productLumens.lumens.raw"
              nestedField="productLumens"
              title="Lumens"
              size={1000}
              showCheckbox={true}
              showSearch={false}
              className="authors"
              innerClass={{
                list: "author-list",

              }}
              react={{
                and: [
                  "mainSearch",
                  "authorsFilter",
                  "sizeFilter",
                  "wattageFilter",
                  "dimmableFilter", 
                  "cctFilter"

                ]
              }}
              placeholder="Filter by Product Lumens"
              filterLabel="Lumens"
            />
             <hr></hr>
            <MultiList
              componentId="wattageFilter"
              dataField="productWattage.wattage.raw"
              nestedField="productWattage"
              title="Wattage"
              size={1000}
              showCheckbox={true}
              showSearch={false}
              className="authors"
              innerClass={{
                list: "author-list",

              }}
              react={{
                and: [
                  "mainSearch",
                  "authorsFilter",
                  "sizeFilter",
                  "lumensFilter",
                 "dimmableFilter"
                ]
              }}
              placeholder="Filter by Product Wattage"
              filterLabel="Wattage"
            />
             <hr></hr>
           <MultiList
              componentId="dimmableFilter"
              dataField="productDimmable.dimmable.raw"
              nestedField="productDimmable"
              title="Dimmable"
              size={1000}
              showCheckbox={true}
              showSearch={false}
              className="authors"
              innerClass={{
                list: "author-list",

              }}
              react={{
                and: [
                  "mainSearch",
                  "authorsFilter",
                  "sizeFilter",
                  "lumensFilter",
                  "wattageFilter", 
                  "cctFilter"
                ]
              }}
              placeholder="Filter by Product Dimmable"
              filterLabel="Dimmable"
            />
             <hr></hr>
             <MultiList
              componentId="cctFilter"
              dataField="productCCT.cct.raw"
              nestedField="productCCT"
              title="CCT"
              size={1000}
              showCheckbox={true}
              showSearch={false}
              className="authors"
              innerClass={{
                list: "author-list",

              }}
              react={{
                and: [
                  "mainSearch",
                  "authorsFilter",
                  "sizeFilter",
                  "lumensFilter",
                  "wattageFilter", 
                  "dimmableFilter"
                ]
              }}
              placeholder="Filter by Color Tempurature"
              filterLabel="Color Tempurature"
            />
             
            
          </div>
         
          <div className={"mainBar"}>
          <DataSearch
            className="datasearch"
            componentId="mainSearch"
            dataField={[
              "productName", 
              "productName.search",
              "productCategories.productCategoryName",
              "productCategories.productCategoryName.search"
              
            ]}
            queryFormat="and"
            placeholder="Search for a product"
            value={this.state.value}
            onChange={this.handleSearch}
            innerClass={{
              input: "searchbox",
              list: "suggestionlist"
            }}
            autosuggest={true}
            iconPosition="left"
            filterLabel="search"
            URLParams={true}
          />
            <SelectedFilters />
            <ReactiveList
              componentId="SearchResult"
              dataField="productName"
              pagination={true}
              className="Result_card"
              paginationAt="both"
              pages={1}
              size={12}
              Loader="Loading..."
              noResults="No results were found..."
              // sortOptions={[
              //    {
              //      dataField: "productName",
              //      sortBy: "asc",
              //      label: "Sort by Product Name (A-Z) \u00A0"
              //    },
              //   {
              //      dataField: "productName",
              //      sortBy: "desc",
              //      label: "Sort by Product Name (Z-A) \u00A0"
              //    }
              //  ]}
              innerClass={{
                
                listItem: "result-item",
              }}
              react={{
                and: [
                  "mainSearch",
                  "authorFilter",
                   "sizeFilter",
                   "lumensFilter",
                  "wattageFilter",
                  "dimmableFilter", 
                  "cctFilter"
                ]
              }}
              render={({ data }) => (
                
                <ReactiveList.ResultCardsWrapper height="1000px"> 
                  {data.map(item => (
                    <Link to={`/productdetailsearch/${encodeURIComponent(item.productName)}` }>
                    <ResultCard
                    //  href={ `/productdetail/${item._id}` }
                     target="_self"
                     key={item._id}>
                      <ResultCard.Image src={item.productDisplayImage} />
                      <br/>
                      {/* <span className="authors-list">
                               Item#: {item.productSku}
                              </span> */}
                       
                      
                      <ResultCard.Title>
                        <div
                          className="book-title"
                          dangerouslySetInnerHTML={{
                            __html: item.productName
                          }}
                        />
                      </ResultCard.Title>

                      <ResultCard.Description>
                        <div className="flex column justify-space-between">
                          <div>
                            <div>
                              <br></br>
                              {/* by{" "}
                              <span className="vendorName">
                                {item.vendorName}
                              </span> */}
                            </div>
                            
                            {/* <div className="ratings-list flex align-center">
                              <span className="stars">
                                {Array(item.average_rating_rounded)
                                  .fill("x")
                                  .map((
                                    item, // eslint-disable-line
                                    index
                                  ) => (
                                    <i
                                      className="fas fa-star"
                                      key={index} // eslint-disable-line
                                    />
                                  ))}
                              </span>
                              <span className="avg-rating">
                                ({item.average_rating} avg)
                              </span>
                            </div> */}
                          </div>
                          <br></br>
                          As Low As:
                          <span className="product-price">
                             <br/> {formatPrice(item.productPrice)}
                          </span>
                                <br/>
                          {/* <div className="flex align-center">
                              This area is reserved for future notices, labels, icons badges indicators, coupons, badges, etc.
                            </div> */}
                        </div>
                      </ResultCard.Description>
                    </ResultCard>
                    </Link>
                  ))}
                </ReactiveList.ResultCardsWrapper>
              )}
            />
          </div>
        </div>
       
    </ReactiveBase>
    </div>
    );
  }

}
 
  export default withRouter(ProductKeywordSearch);
