import React, { Component } from "react";
import bwlogo from '../assets/bwlogo.svg';
import { Row, Col } from "reactstrap";
import insta from "../assets/instagram.svg";
import facebook from "../assets/facebook.svg";
import telephone from "../assets/telephone.svg";
import email from "../assets/email.svg";




class TermsOfSale extends Component {

   
  render() {
    return (
  <div className="p-3 text-center">
    
      Terms of Sale Content
  </div>
);
}
}
export default TermsOfSale;
