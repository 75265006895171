import React, { Component } from "react";
import bwlogo from '../assets/bwlogo.svg';
import { Container, Row, Col } from "reactstrap";
import insta from "../assets/instagram.svg";
import facebook from "../assets/facebook.svg";
import telephone from "../assets/telephone.svg";
import email from "../assets/email.svg";
import { Route , withRouter} from 'react-router-dom';
import { format2decimal } from "../helpers";
import { formatPrice } from "../helpers";
import ProceedToCheckOut from '../assets/proceed-to-checkout.png';


import CartItemTemplate from './CartItemTemplate';
import { Tab } from "bootstrap";


function wait(ms)
{
var d = new Date();
var d2 = null;
do { d2 = new Date(); }
while(d2-d < ms);
}



class Cart extends Component {
    constructor() {
        super();    
    
    
        this.state = {
            // cartData: { CartID: '10003',
            //              CartName: 'Test Cart Current', 
            //              CartDescription: 'Test For Golf Items',
            // cartSummary: [{ Price: 0.00, SalesTax: 0.00, GrandTotal: 0.00 }],
            // cartItems: [{cartProductXrefID: 0, cartID: 0, productID: 0, productQuantity: 0, productPrice: 0, productPartDetailID: 0, productCost: 0, productName: '', productDesc: '', productSKU: '',  imageURL: '', unitQuantity: 0, priceTypeID: 0, priceTypeDescription: '' }], 
            
            // },

            
            cartSummary: [{ Price: 0.00, SalesTax: 0.00, GrandTotal: 0.00 }],
            cartItems: [{cartProductXrefID: 0, cartID: 0, productID: 0, productQuantity: 0, productPrice: 0, productPartDetailID: 0, productCost: 0, productName: '', productDesc: '', productSKU: '',  imageURL: '', unitQuantity: 0, priceTypeID: 0, priceTypeDescription: '' }], 
            InitalBoxColor: '',
            LabelValue: '',
            selectedPPAId: '',
            Increments: [{MinQuantity : 1}],
            showCart: false,
            
            
        
        
          
            }
            this.deleteCartItem = this.deleteCartItem.bind(this);

    }
    
    
    componentDidMount() {

      // localStorage.removeItem("cartItems") 
      
      const cartID = this.props.match.params.cartid;

      let localCart = localStorage.getItem("cartItems");

      this.setState({cartItems: JSON.parse(localCart)});
      
      let localCartSummary = [];
      let localArray = [{cartProductXrefID: 0, cartID: 0, productID: 0, productQuantity: 0, productPrice: 0, productPartDetailID: 0, productCost: 0, productName: '', productDesc: '', productSKU: '',  imageURL: '', unitQuantity: 0, priceTypeID: 0, priceTypeDescription: '' }];
    
      let itemArray = [0];

      localArray = JSON.parse(localCart);

      for(var i=0; i < localArray.length; i++)
      {
        itemArray.push(localArray[i].productPrice);
      }

      var total = 0;
      total = itemArray.reduce((a, b) => a + b, 0);

         
        
      
      
      

      localCartSummary.push({"Price": total.toFixed(2), "SalesTax": 0.00, "GrandTotal": total.toFixed(2)});

      console.log(localCartSummary);

      this.setState({cartSummary: localCartSummary}, () => {
        console.log(this.state.cartSummary, 'cartSummary');
      });
      

      // console.log(cartID);

      //   fetch(`https://services.hatterasmarket.com/api/carts/1`)  
      //   .then(res => res.json())
      //   .then((data) => {
      //     this.setState({ cartData: data })
      //     this.setState({cartSummary: data.cartSummary})
      //     this.setState({cartItems: data.cartItems})
      //           console.log(this.state.cartItems)
      //   })
      //   .catch(console.log)
    
      
        
    
        
      }

      deleteCartItem = (CartItemID, CartItemName) => {

        //alert("You deleted " + CartItemName)
        

        let cartItemsCopy = this.state.cartItems;
        
        cartItemsCopy = cartItemsCopy.filter(item => item.cartProductXrefID != CartItemID);
        
        // console.log(CartItemID)
        // console.log(cartItemsCopy)
  
  localStorage.setItem('cartItems', JSON.stringify(cartItemsCopy));
        
    //     fetch(`https://services.hatterasmarket.com/api/carts/${CartItemID}`, {
    //       method: "Delete",
    //       crossDomain:true,
    //   mode: "cors", // or without this line
    // redirect: 'follow',
    // headers: {
    //     "Content-type": "application/json",
    //     "Accept": "application/json",
    //     "Accept-Charset": "utf-8"
        
    // },
    //   }).then((res) => res.text())
    //   .then((data) =>  console.log(data))
    //   .catch((err)=> console.log(err));
  
     this.reloadCarts();
      
  
    }
    
    reloadCarts  = () => {
  

      const cartID = this.props.match.params.cartid;

      let localCart = localStorage.getItem("cartItems");

      this.setState({cartItems: JSON.parse(localCart)});

      let localCartSummary = [];
      let localArray = [{cartProductXrefID: 0, cartID: 0, productID: 0, productQuantity: 0, productPrice: 0, productPartDetailID: 0, productCost: 0, productName: '', productDesc: '', productSKU: '',  imageURL: '', unitQuantity: 0, priceTypeID: 0, priceTypeDescription: '' }];
    
      let itemArray = [0];

      localArray = JSON.parse(localCart);

      for(var i=0; i < localArray.length; i++)
      {
        itemArray.push(localArray[i].productPrice);
      }

      var total = 0;
      total = itemArray.reduce((a, b) => a + b, 0);


         
        
      
      
      

      localCartSummary.push({"Price": total.toFixed(2), "SalesTax": 0.00, "GrandTotal": total.toFixed(2)});

      console.log(localCartSummary);

      this.setState({cartSummary: localCartSummary}, () => {
        console.log(this.state.cartSummary, 'cartSummary');
      });

      

        // fetch(`https://services.hatterasmarket.com/api/carts/1`)  
        // .then(res => res.json())
        // .then((data) => {
        //   this.setState({ cartData: data })
        //   this.setState({cartSummary: data.cartSummary})
        //   this.setState({cartItems: data.cartItems})
        //         console.log(this.state.cartItems)
        // })
        // .catch(console.log)
    
      
      
    }

    goToCarts = event => {
      // 1. Stop the form from submitting
      event.preventDefault();
      
      // 3. Change the page to /store/whatever-they-entered
      this.props.history.push(`/cartdetail/1`);
    
    };
    
    goToSearch = event => {
      // 1. Stop the form from submitting
      event.preventDefault();
      
      // 3. Change the page to /store/whatever-they-entered
      this.props.history.push(`/search/all`);
    
    };
    
    render() {
        return (
 
        <table >
          <tr>
            <td width="25px"></td>
            <td>
            <span align="left"><h2>My Cart</h2></span>
            </td>
          </tr>
            <tr>
            <td colspan="2" width="864px">
            
            

            {!this.state.cartItems.length ? (
                <span>No Items</span>
      ) : (
        this.state.cartItems.map((item) =>  
                         <CartItemTemplate key={item.cartProductXrefID} 
                           CartProductXrefID={item.cartProductXrefID}  
                           CartID={item.cartID}  
                           ProductID={item.productID}  
                           ProductQuantity={item.productQuantity}
                           ProductPrice={item.productPrice} 
                           ProductPartDetailID={item.productPartDetailID} 
                           ProductCost={item.productCost} 
                           ProductName={item.productName} 
                           ProductDesc={item.productDesc}  
                           ProductSKU={item.productSKU}  
                           ImageURL={item.imageURL} 
                           UnitQuantity={item.unitQuantity}
                           PriceTypeID={item.priceTypeID}
                           PriceTypeDescription={item.priceTypeDescription}
                           UnitQuantity={item.unitQuantity}
                           deleteCartItem = {this.deleteCartItem}
                           />
      ))
    }
           
            </td>
            <td width="20px"></td>
              <td valign="top">
            { this.state.cartSummary.map((item) =>  
            <table  width="267" className="rounded-corners" valign="top">
                    <tbody>
                    <tr>
                            <td width="5px"></td>
                            <td  valign="top" colSpan="2"  className="cartSummary"><br/><b>Order Summary</b><br></br>
                                
                            </td>
                            <td width="5px"></td>
                        </tr>
                         {/* <tr>
                          <td className="totals-format" align="left" width="140">Items</td>
                          <td className="totals-format" align="right"> {formatPrice(item.Price)}</td>
                        </tr> */}
                        {/* <tr>
                          <td className="totals-format" align="left" width="140">Shipping and Handling</td>
                          <td className="totals-format"  align="right">$00.00</td>
                        </tr> */}
                        {/* <tr>
                          <td className="totals-format" align="left"  width="140">Total Before Tax</td>
                          <td className="totals-format"  align="right">{formatPrice(item.Price)}</td>
                        </tr> */}
                        {/* <tr>
                          <td className="totals-format" align="left"  width="140">Estimated Tax</td>
                          <td className="ctotals-format"  align="right">{formatPrice(item.SalesTax)}</td>
                        </tr> */}
                        
                        <tr>
                        <td width="5px"></td>
                          <td className="cartSummaryTextLeft" align="left">Order Total<br></br><br></br></td>
                          <td className="cartSummaryTextRight"  align="right">{formatPrice(item.GrandTotal)}<br></br><br></br></td>
                          <td width="5px"></td>
                        </tr>
                        <tr>
                            <td  valign="top" colSpan="4"  className="cartSummary">
                                <img src={ProceedToCheckOut} /><br></br>
                            </td>
                        </tr>
                       
                     
                        
                        </tbody>
                    </table>
            )
          }
                
            </td>
            </tr>
        </table>
          
                         
                         
                        
                    

                     
            
             
        
        );
}

}
export default withRouter(Cart);


