import React, { useState } from "react";
import { Component } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from '../assets/logo.svg';
import signIn from '../assets/signIn.svg';
import '../App.css';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';



import { formatPrice } from "../helpers";
import {
  ReactiveBase,
  DataSearch,
  MultiList,
  SelectedFilters,
  ResultCard,
  ReactiveList,
  MultiRange
} from "@appbaseio/reactivesearch";
//import "./App.css";
import "../App.css";





    class ProductDetailSearch extends Component {
      constructor(props) {
        super(props);
      this.state = {
        categoryList: ['', ''],
        categoriesToHand: [{category : ''}],
        keywordList: [{keyword : ''}],
        categoryValue: '' ,
        defaultValue: ''
      }
    }

     
      
    

      
     

     componentDidMount()
     {
      console.log("props");
     
      
   
     }

      
    

  
    

   


      
      render() {
        return (
    <div className="text-center my-5">
       <table>
      <tr>
      <td width="25px"></td>
      <td>
      <span align="left" className="header-span"><h2>Products</h2></span>
      </td>
      </tr>
      </table>
    <ReactiveBase
    app="productdetails"
    url="https://search.buydirectpro.com"
    //url="http://10.0.3.54:2903"
  >


<div className={"display"}>
          <div className={"leftSidebar"}>
            
            
          
            <MultiList
              componentId="authorsFilter"
              dataField="productName.raw"
              title="Products"
              showSearch={false}
              //size={1000}
              showCheckbox={true}
              className="authors"
              showCount={false}

              innerClass={{
                list: "author-list",

              }}
              react={{
                and: [
                  
                //    "sizeFilter",
                   "cnFilter",
                   "wattageFilter",
                   "lumensFilter",
                  "dimmableFilter", 
                  "cctFilter",
                  "mainSearch"
                ]
              }}
              //placeholder="Filter by Product Category"
              filterLabel="Products"
              //URLParams={false}
              // defaultValue={[this.props.match.params.productName]}
              
            />
            {/* <hr></hr> */}

            <MultiList
              componentId="cnFilter"
             dataField="productCategories.productCategoryName.raw"
              nestedField="productCategories"
              title="Product Categories"
              showSearch={false}
              //size={1000}
              showCheckbox={true}
              className="authors"
              showCount={false}
              innerClass={{
                list: "author-list",

              }}
              react={{
                and: [
                  "mainSearch",
                  "authorsFilter",
                //   "sizeFilter",
                  "lumensFilter",
                  "wattageFilter", 
                  "cctFilter",
                  "dimmableFilter"
                 
                ]
              }}
              //placeholder="Filter by Product Category"
              filterLabel="Categories"
              //URLParams={false}
              // defaultValue={[this.props.match.params.categoryName]}
             
              
            />
            <hr></hr>

            <MultiRange
  componentId="wattageFilter"
  dataField="productWattage"
  title="Wattage"
  size={1000}
  showCheckbox={true}
  showSearch={false}
  data={
    [{"start": 0, "end": 50, "label": "0-50"},
     {"start": 51, "end": 99, "label": "51-99"},
     {"start": 100, "end": 200, "label": "100-200"},
     {"start": 201, "end": 300, "label": "201-300"},
     {"start": 301, "end": 500, "label": "301-500"},
     {"start": 500, "end": 10000, "label": "500+"},
    ]

  }
  innerClass={{
    list: "author-list",

  }}
  react={{
    and: [
      "mainSearch",
      "authorsFilter",
    //   "sizeFilter",
      "lumensFilter",
     "dimmableFilter",
     "cctFilter",
      "cnFilter"
    ]
  }}
  placeholder="Filter by Product Wattage"
  filterLabel="Wattage"
/>

            {/* <MultiList
              componentId="wattageFilter"
              dataField="productWattage.raw"
              title="Wattage"
              size={1000}
              showCheckbox={true}
              showSearch={false}
              className="authors"
              innerClass={{
                list: "author-list",

              }}
              react={{
                and: [
                  "mainSearch",
                  "authorsFilter",
                //   "sizeFilter",
                  "lumensFilter",
                 "dimmableFilter",
                 "cctFilter"
                ]
              }}
              placeholder="Filter by Product Wattage"
              filterLabel="Wattage"
            /> */}
             <hr></hr>


             <MultiRange
  componentId="lumensFilter"
  dataField="productLumens"
  title="Lumens"
  size={1000}
  showCheckbox={true}
  showSearch={false}
  data={
    [{"start": 0, "end": 100, "label": "0-100"},
     {"start": 101, "end": 300, "label": "101-300"},
     {"start": 301, "end": 500, "label": "301-500"},
     {"start": 501, "end": 800, "label": "501-800"},
     {"start": 801, "end": 1100, "label": "801-1100"},
     {"start": 1101, "end": 1500, "label": "1101-1500"},
     {"start": 1501, "end": 2000, "label": "1501-2000"},
     {"start": 2001, "end": 3000, "label": "2001-3000"},
     {"start": 3001, "end": 5000, "label": "3001-5000"},
     {"start": 5001, "end": 8000, "label": "25001-8000"},
     {"start": 8001, "end": 12500, "label": "9001-12500"},
     {"start": 12501, "end": 18000, "label": "12501-18000"},
     {"start": 18001, "end": 35000, "label": "18001-35000"},
     {"start": 35001, "end": 120000, "label": "35000+"},
    ]
  }
  innerClass={{
    list: "author-list",

  }}
  react={{
    and: [
      "mainSearch",
      "authorsFilter",
    //   "sizeFilter",
      "wattageFilter",
      "dimmableFilter", 
      "cctFilter",
       "cnFilter"

    ]
  }}
  placeholder="Filter by Product Wattage"
  filterLabel="Wattage"
/>
            {/* <MultiList
              componentId="lumensFilter"
              dataField="productLumens.raw"
              title="Lumens"
              size={1000}
              showCheckbox={true}
              showSearch={false}
              className="authors"
              innerClass={{
                list: "author-list",

              }}
              react={{
                and: [
                  "mainSearch",
                  "authorsFilter",
                //   "sizeFilter",
                  "wattageFilter",
                  "dimmableFilter", 
                  "cctFilter"

                ]
              }}
              placeholder="Filter by Product Lumens"
              filterLabel="Lumens"
            /> */}
             <hr></hr>
            
           <MultiList
              componentId="dimmableFilter"
              dataField="productDimmable.raw"
              title="Dimmable"
              size={1000}
              showCheckbox={true}
              showCount={false}
              showSearch={false}
              className="authors"
              innerClass={{
                list: "author-list",

              }}
              react={{
                and: [
                  "mainSearch",
                  "authorsFilter",
                //   "sizeFilter",
                  "lumensFilter",
                  "wattageFilter", 
                  "cctFilter",
                   "cnFilter"
                ]
              }}
              placeholder="Filter by Product Dimmable"
              filterLabel="Dimmable"
            />
             <hr></hr>
             <MultiList
              componentId="cctFilter"
              dataField="productCCT.raw"
              title="CCT"
              size={1000}
              showCheckbox={true}
              showSearch={false}
              showCount={false}
              className="authors"
              innerClass={{
                list: "author-list",

              }}
              react={{
                and: [
                  "mainSearch",
                  "authorsFilter",
                //   "sizeFilter",
                  "lumensFilter",
                  "wattageFilter", 
                  "dimmableFilter",
                   "cnFilter"
                ]
              }}
              placeholder="Filter by Color Tempurature"
              filterLabel="Color Tempurature"
            />
             {/* <MultiList
              componentId="sizeFilter"
              dataField="productSize.raw"
              title="Product Size"
              size={1000}
              showCheckbox={true}
              showSearch={false}
              className="authors"
              innerClass={{
                list: "author-list",

              }}
              react={{
                and: [
                  "mainSearch",
                  "authorsFilter",
                  "lumensFilter",
                  "wattageFilter",
                  "dimmableFilter", 
                  "cctFilter"
                ]
              }}
              placeholder="Filter by Product Size"
              filterLabel="Size"
              
            /> 
<hr></hr> */}

            
          </div>
         
          <div className={"mainBar"}>
          <DataSearch
            className="datasearch"
            componentId="mainSearch"
            dataField={[
              "productName", 
              "productName.search",
              "productCategories.productCategoryName",
              "productCategories.productCategoryName.search",
              "productSku",
              "productSku.search"
              
            ]}
            queryFormat="and"
            //width="600px"
            placeholder="Search for a product"
            innerClass={{
              input: "searchbox",
              list: "suggestionlist"
            }}
            autosuggest={false}
            iconPosition="left"
            showIcon={false}
            filterLabel="search"
            URLParams={true}
          />
            <SelectedFilters />
            <ReactiveList
              componentId="SearchResult"
              dataField="productName.raw"
              pagination={true}
              className="Result_card"
              paginationAt="both"
              pages={5}
              size={12}
              Loader="Loading..."
              noResults="No results were found..."
              // sortOptions={[
              //    {
              //      dataField: "productName",
              //      sortBy: "asc",
              //      label: "Sort by Product Name (A-Z) \u00A0"
              //    },
              //   {
              //      dataField: "productName",
              //      sortBy: "desc",
              //      label: "Sort by Product Name (Z-A) \u00A0"
              //    }
              //  ]}
              innerClass={{
                
                listItem: "result-item",
              }}
              react={{
                and: [
                 
                  "authorsFilter",
                //    "sizeFilter",
                   "lumensFilter",
                  "wattageFilter",
                  "dimmableFilter", 
                  "cctFilter",
                  "mainSearch",
                   "cnFilter"
                ]
              }}
              render={({ data }) => (
                
                <ReactiveList.ResultCardsWrapper > 
                  {data.map(item => (
                    <ResultCard
                     href={ `/productdetail/${item.productID}/${item.productPartDetailID}` }
                     target="_self"
                     key={item.productPartDetailID}>
                      <ResultCard.Image src={item.productDisplayImage} />
                      <br/>
                      <span className="authors-list">
                               SKU#: {item.productSku}
                              </span>
                       
                      
                      <ResultCard.Title>
                        <div
                          className="book-title"
                          dangerouslySetInnerHTML={{
                            __html: item.productName /* + ' <br/> ' + item.productSku */
                          }}
                        />
                      </ResultCard.Title>

                      <ResultCard.Description>
                        <div className="flex column justify-space-between">
                          <div>
                            
                         
                          </div>
                          <br></br>
                          As Low As:
                          <span className="product-price">
                             <br/> {formatPrice(item.productPrice)}
                          </span>
                                <br/>
                            
                          {/* <div className="flex align-center">
                              This area is reserved for future notices, labels, icons badges indicators, coupons, badges, etc.
                            </div> */}
                        </div>
                      </ResultCard.Description>
                    </ResultCard>
                  ))}
                </ReactiveList.ResultCardsWrapper>
              )}
            />
          </div>
        </div>
       
    </ReactiveBase>
    </div>
    );
  }

}
 
  export default withRouter(ProductDetailSearch);
