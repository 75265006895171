import React from 'react';
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import Loading from "./components/Loading";
import Cart from './components/Cart'
import NavBar from "./components/NavBar";
import ProductSearch from "./components/ProductSearch";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from './views/Profile'
import MyProfile from "./components/MyProfile";
import ProductCategorySearch from "./components/ProductCategorySearch";
import ProductKeywordSearch from "./components/ProductKeywordSearch";
import { useAuth0 } from "@auth0/auth0-react";
import AboutUs from "./components/AboutUs";
import history from "./utils/history";
import ProductDetail from "./components/ProductDetail";
import HowToBuy from "./components/HowToBuy";
import Privacy from "./components/Privacy";
import TermsOfSale from "./components/TermsOfSale";
import TermsOfUse from "./components/TermsOfUse";
import OrderPolicy from "./components/OrderPolicy";
import ShippingAndDeliveryPolicy from "./components/ShippingAndDeliveryPolicy";
import Contact from "./components/Contact.js";
import EditProfile from "./components/EditProfile";
import ShippingAddress from "./components/ShippingAddress";
import CommPreferences from './components/CommPreferences';
import EditShippingAddress from './components/EditShippingAddress';
import AddShippingAddress from './components/AddShippingAddress';
import ProductDetailSearch from './components/ProductDetailSearch';
import WhyBuyDirect from './components/WhyBuyDirect';
import SimplifyProcurement from './components/SimplifyProcurement';
import FlexibleOptions from './components/FlexibleOptions';
import LifeWave from './components/LifeWave';

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import FAQ from "./components/FAQ";

initFontAwesome();

const App = () => {
  const { isLoading, error, user, isAuthenticated } = useAuth0();
  

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }
  
  

  if(isAuthenticated)
  {  

    window.localStorage.removeItem("bdUserProfile");
    
    let bdUserProfile = [];

  if(localStorage.getItem('bdUserProfile')){
    bdUserProfile = JSON.parse(localStorage.getItem('bdUserProfile'));
    }

bdUserProfile.push({'userEmail' : user.email, 'userID' : user.sub});
localStorage.setItem('bdUserProfile', JSON.stringify(bdUserProfile));
}
  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
        <Container className="flex-grow-1 mt-0">
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/productsearch" component={ProductSearch} />
            <Route path="/productsearch/:searchTerm" component={ProductSearch} />
            {/* <Route path="/productsearch/category/:categoryName" component={ProductSearch} /> */}
            <Route path="/productcategorysearch/:categoryName" component={ProductCategorySearch} />
            <Route path="/productkeywordsearch/:keyword" component={ProductKeywordSearch} />
            <Route path="/productdetail/:productid/:productDetailID" component={ProductDetail} />
            <Route path="/cart" component={Cart} />
            <Route path="/cart/:cartid" component={Cart} />
            <Route path="/faq" component={FAQ} />
            <Route path="/about" component={AboutUs} />
            <Route path="/contactus" component={Contact} />
            <Route path="/myprofile" component={MyProfile} />
            <Route path="/editprofile" component={EditProfile} />
            <Route path="/profile" component={Profile} />
            <Route path="/shippingaddresses" component={ShippingAddress} />
            <Route path="/commpreferences" component={CommPreferences} />
            <Route path="/howtobuy" component={HowToBuy} />
            <Route path="/termsofsale" component={TermsOfSale} />
            <Route path="/termsuse" component={TermsOfUse} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/orderpolicy" component={OrderPolicy} />
            <Route path="/shipanddeliverpolicy" component={ShippingAndDeliveryPolicy} />
            <Route path="/editshippingaddress/:addressID" component={EditShippingAddress} />
            <Route path="/addshippingaddress" component={AddShippingAddress} />
            <Route path="/productdetailsearch" component={ProductDetailSearch} />
            <Route path="/productdetailsearch/3/:topCategoryName" component={ProductDetailSearch} />
            <Route path="/whybuy" component={WhyBuyDirect} />
            <Route path="/simplifyprocurement" component={SimplifyProcurement} />
            <Route path="/flexibleoptions" component={FlexibleOptions} />
            <Route path="/lifewave" component={LifeWave} />
            

            {/* <Route path="/productdetailsearch" component={ProductDetailSearch} /> */}

          
          </Switch>
        </Container>
        <Footer />
      </div>
    </Router>
  );
};



export default App;
