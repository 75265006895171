import React, { Component } from "react";
import bwlogo from '../assets/bwlogo.svg';
import insta from "../assets/instagram.svg";
import facebook from "../assets/facebook.svg";
import telephone from "../assets/telephone.svg";
import email from "../assets/email.svg";
import "../App.css";
import { Container, Row, Col } from "reactstrap";
import AboutUsHeader from "../assets/AboutUsHeader.png";
import AboutUsSimplicity from '../assets/AboutUsSimplicity.png';
import AboutUsBTO from '../assets/AboutUsBTO.png';
import AboutUsFreeShipping from '../assets/AboutUsFreeShipping.png';
import AboutUsCustomerService from '../assets/AboutUSCustomerService.png';
import NoHassle from "../assets/No Hassle.png";
import AdvancedTechnology from "../assets/Advanced Technology.png";
import WeDeliverValue from "../assets/We Deliver Value.png";
import BestPrices from '../assets/Best Prices.png';
import EliminateMarkup from '../assets/Eliminate Markup.png';
import CertifiedSuppliers from '../assets/Certified Suppliers.png';
import BuildToOrder from '../assets/BuildToOrder.png';
import ProductBundles from '../assets/Product Bundles.png';
import GlobalLogistics from '../assets/Global Logistics.png';
import LwsLogo from '../assets/LWS-logo.jpg';
import AssociationLogo from '../assets/AssociationLogos.PNG';
import ETLLogo from '../assets/ETLLOGO.jpg';
import CULUS1 from '../assets/Classified-UL-US-1.png';
import CULListed from '../assets/CULLISTED.jpeg';






class LifeWave extends Component {

   
  render() {
    return (
<div className="text-left"><br/><br/>
   <h3>BuyDirectPro Supplier Partner Profile </h3>
   <br/>
   
   <br></br>
   <table>
        <tr>
            <td width="46%" valign="top">
            <h4>LifeWave Solutions</h4>
            LifeWave Solutions is a leading “vertically integrated” manufacturer for LED products providing the latest technology of standard and custom LED lighting products specializing in R&D, Design, Manufacturing of LED Chips, LED Packages, LED Light Engines and complete LED Light Fixtures. They have been in operation since 2005. <br/>
            <br/>
            LifeWave Solutions facilities are ISO14001, ISO9001, TS16949, OHSA1800, RoHS, FCC, UL and DLC certified. LifeWave Solutions is an Energy Star Partner. <br/>
            </td>
            <td width="8%"></td>
            <td width="46%" valign="middle">
                <img src={LwsLogo} heigh="182" width="315" />
            </td>
        </tr>
        <tr>
            <td colspan="3"><br/><br/><br/>
                <h3>Professional Associations </h3><br/>
                <img src={AssociationLogo} width="800"></img>
            </td>
        </tr> 
        <tr>
            <td colspan="3"><br/><br/><br/>
                <h3>Patents</h3>
                <br/>
                <ul>
                <li>LED Monster Reading Lamp: US D608937, CANADA 131905 </li>

                <li>LED Turtle Reading Lamp: US D608936, CANADA D131904 </li>

                <li>LED Strips: US 8262250 , US 61/079042, CANADA 671360 </li>

                <li>LED Spotlight: US 7972040, CANADA 2676315 </li>

                <li>LED Lamp Assembly: US 13/110457, US 61/091072 </li>

                <li>LED White Light: US 12/370685, CANADA 2656314 </li>

                <li>LED Light Source Module: US 12/473468, CANADA 2675193 </li>

                <li>LED Waterproof Strips: US 12/975112, US 61/288815, CANADA 2726875 </li>

                <li>LED Strip Lighting Connector: US 13/106558, US 12/975445, CANADA 2740387 </li>

                <li>LED Module: US 12/975445, CANADA 2726179 </li>
                </ul>
            </td>
        </tr> 

        <tr>
            <td colspan="3"><br/><br/><br/>
                <h3>Approved Certificates </h3>
                <br/>
                <table>
                <tr>
                    <td><img src={CULListed} width="141" height="125"></img></td>
                    <td width="30px"></td>
                    <td><img src={CULUS1} width="141" height="125"></img></td>
                    <td width="30px"></td>
                    <td><img src={ETLLogo} width="141" height="125"></img></td>
                    </tr>
                </table>
                
                
                
                <br/><br/>
                <ul>
                <li>LED Spotlight: cULus E324837 </li>

                <li>LED Flex Strips: cULus E321933; cULus E355338 </li>

                <li>LED Hard Strips: cULus E321933 </li>

                <li>LED Shelf Light: cULus E321933 </li>

                <li>LED Reading Light: cULus E329183 </li>

                <li>LED T8: cULus E335470 </li>

                <li>LED High Bay Light: cULus E335469 </li>

                <li>LED Module: cULus E301137 </li>

                <li>LED Power Supply: cULus E324838 </li>

                <li>LED G2 Lamp: Pending cULus E324837 </li>

                <li>LED Street Light: Pending cULus E335469 </li>

                <li>LED T5: cETLus GZ10040056-1 </li>
                </ul>
            </td>
        </tr> 
        
   </table>
</div>
);
}
}
export default LifeWave;